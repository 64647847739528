import React from 'react';
import { Bill } from '../../types/bill';
import { isValidDecimalInput, calculateBillTotals } from '../../utils/calculations';
import { useBillContext, billActions } from '../../contexts/BillContext';

interface TotalsTableProps {
  bill: Bill;
}

export const TotalsTable: React.FC<TotalsTableProps> = ({
  bill,
}) => {
  const { dispatch } = useBillContext();

  const handleAmountChange = (field: keyof Bill, value: string) => {
    if (value === '' || isValidDecimalInput(value)) {
      // Update the bill with the new value
      const updatedBill = {
        ...bill,
        [field]: value
      };

      // Immediately recalculate totals
      const totals = calculateBillTotals(
        updatedBill.items,
        updatedBill.oldGoldSilverItems || [],
        updatedBill.isTaxEnabled,
        updatedBill.schemeAmount,
        updatedBill.receivedAmount,
        updatedBill.givenAmount,
        updatedBill.roundOffAmount,
        updatedBill.previousOutstanding
      );

      // Update both the field value and totals
      dispatch(billActions.setBill({
        ...updatedBill,
        ...totals
      }));
    }
  };

  const toggleTax = () => {
    // Toggle tax and immediately recalculate totals
    const updatedBill = {
      ...bill,
      isTaxEnabled: !bill.isTaxEnabled
    };

    const totals = calculateBillTotals(
      updatedBill.items,
      updatedBill.oldGoldSilverItems || [],
      updatedBill.isTaxEnabled,
      updatedBill.schemeAmount,
      updatedBill.receivedAmount,
      updatedBill.givenAmount,
      updatedBill.roundOffAmount,
      updatedBill.previousOutstanding
    );

    dispatch(billActions.setBill({
      ...updatedBill,
      ...totals
    }));
  };

  const formatAmount = (amount: number) => amount.toFixed(2);

  return (
    <div className="mb-4 grid grid-cols-2">
      <div></div>
      <div className="flex justify-center">
        <div className="w-[350px]">
          <h3 className="text-lg font-semibold mb-2">Totals</h3>
          <table className="w-full mb-4 border-collapse border border-gray-300">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Items Net Amount
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right w-28">
                  {formatAmount(bill.itemsNetAmount)}
                </td>
              </tr>
              
              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  <div className="flex items-center">
                    <span className="mr-2">Tax Amount (3%)</span>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={bill.isTaxEnabled}
                        onChange={toggleTax}
                        className="sr-only peer"
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right w-28">
                  {formatAmount(bill.taxAmount || 0)}
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Total Amount
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right w-28">
                  {formatAmount(bill.totalAmount)}
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Old Gold Amount
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right w-28">
                  {formatAmount(bill.oldGoldAmount || 0)}
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Old Silver Amount
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right w-28">
                  {formatAmount(bill.oldSilverAmount || 0)}
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Scheme Amount
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={bill.schemeAmount || ''}
                    onChange={(e) => handleAmountChange('schemeAmount', e.target.value)}
                    className="w-full px-2 text-right"
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="h-4"></td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Received Amount
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={bill.receivedAmount || ''}
                    onChange={(e) => handleAmountChange('receivedAmount', e.target.value)}
                    className="w-full px-2 text-right"
                  />
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Given Amount
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={bill.givenAmount || ''}
                    onChange={(e) => handleAmountChange('givenAmount', e.target.value)}
                    className="w-full px-2 text-right"
                  />
                </td>
              </tr>

              <tr>
                <td className="border border-gray-300 p-0 px-2 font-semibold">
                  Round off Amount
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={bill.roundOffAmount || ''}
                    onChange={(e) => handleAmountChange('roundOffAmount', e.target.value)}
                    className="w-full px-2 text-right"
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="h-4"></td>
              </tr>

              <tr className="bg-gray-50">
                <td className="border border-gray-300 p-0 px-2 font-bold">
                  Outstanding Amount
                </td>
                <td className="border border-gray-300 p-0 px-2 text-right font-bold">
                  {formatAmount(bill.osAmount || 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
