import React from 'react';

interface NavigationFooterProps {
  voucherNumber: number;
  handlePreviousBill: () => void;
  handleNextBill: () => void;
  setIsPreviousBillsOpen: (isOpen: boolean) => void;
  editingBillId: string | null;
  isSidebarOpen?: boolean;
}

const NavigationFooter: React.FC<NavigationFooterProps> = ({
  voucherNumber,
  handlePreviousBill,
  handleNextBill,
  setIsPreviousBillsOpen,
  editingBillId,
  isSidebarOpen = true
}) => {
  return (
    <div 
      className={`
        fixed bottom-0 bg-white shadow-lg p-4 flex items-center justify-between transition-all duration-300
        ${isSidebarOpen ? 'left-64' : 'left-0'}
      `}
      style={{
        width: isSidebarOpen ? 'calc(100% - 256px)' : '100%',
        zIndex: 50,
        transitionProperty: 'all',
        transitionDuration: '300ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
      }}
    >
      <div className="flex items-center space-x-4">
        {voucherNumber > 1 && (
          <button
            type="button"
            onClick={handlePreviousBill}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            title="Previous Loan"
          >
            &lt;
          </button>
        )}
        <button
          type="button"
          onClick={() => setIsPreviousBillsOpen(true)}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          View Previous Loans
        </button>
        <button
          type="button"
          onClick={handleNextBill}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          title={voucherNumber ? "Next Loan" : "Create New Loan"}
        >
          &gt;
        </button>
      </div>
      <div className="flex items-center space-x-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {editingBillId ? 'Update' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default NavigationFooter;
