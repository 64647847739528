import React from 'react';
import { VendorFineCutForm } from '../features/vendorFineCut';

const VendorFineCut: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <VendorFineCutForm />
    </div>
  );
};

export default VendorFineCut;
