import React from 'react';
import { Item } from '../../../shared/ItemSearch/types/item';
import { ItemSearch } from '../../../shared/ItemSearch/components';
import { GoldItem } from '../api/VendorBillAPI';

export interface GoldItemsTableProps {
  items: Item[];
  tableItems: GoldItem[];
  setTableItems: (items: GoldItem[]) => void;
}

const emptyItem: GoldItem = {
  itemId: '',
  itemName: '',
  pcs: '0',
  grossWt: '0',
  lessWt: '0',
  netWt: '0',
  tunch: '0',
  wstg: '0',
  fine: '0'
};

const GoldItemsTable: React.FC<GoldItemsTableProps> = ({ items = [], tableItems, setTableItems }) => {
  const addNewRow = () => {
    setTableItems([...tableItems, { ...emptyItem }]);
  };

  const handleInputChange = (index: number, field: keyof GoldItem, value: string) => {
    const newItems = [...tableItems];
    
    // Ensure value is not negative
    const numValue = parseFloat(value) || 0;
    const validValue = Math.max(0, numValue).toString();
    
    newItems[index] = { ...newItems[index], [field]: validValue };

    // Calculate Net Wt
    if (field === 'grossWt' || field === 'lessWt') {
      const grossWt = parseFloat(newItems[index].grossWt) || 0;
      const lessWt = parseFloat(newItems[index].lessWt) || 0;
      newItems[index].netWt = Math.max(0, grossWt - lessWt).toFixed(3);

      // Recalculate fine since netWt changed
      const tunch = parseFloat(newItems[index].tunch) || 0;
      const wstg = parseFloat(newItems[index].wstg) || 0;
      const netWt = parseFloat(newItems[index].netWt) || 0;
      const fine = (netWt * (tunch + wstg)) / 100;
      newItems[index].fine = fine.toFixed(3);
    }

    // Calculate Fine
    if (field === 'netWt' || field === 'tunch' || field === 'wstg') {
      const netWt = parseFloat(newItems[index].netWt) || 0;
      const tunch = parseFloat(newItems[index].tunch) || 0;
      const wstg = parseFloat(newItems[index].wstg) || 0;
      const fine = (netWt * (tunch + wstg)) / 100;
      newItems[index].fine = fine.toFixed(3);
    }

    setTableItems(newItems);
  };

  const handleItemSearch = (index: number, searchTerm: string) => {
    const newItems = [...tableItems];
    newItems[index] = {
      ...newItems[index],
      itemName: searchTerm,
      itemId: ''
    };
    setTableItems(newItems);
  };

  const handleItemSelect = (index: number, selectedItem: Item) => {
    const newItems = [...tableItems];
    const currentItem = newItems[index];

    // Keep existing values if they're meaningful, otherwise use defaults
    newItems[index] = {
      ...currentItem,
      itemId: selectedItem._id,
      itemName: selectedItem.name,
      // Keep existing values only if they're meaningful
      pcs: parseFloat(currentItem.pcs) > 0 ? currentItem.pcs : '0',
      grossWt: parseFloat(currentItem.grossWt) > 0 ? currentItem.grossWt : '0',
      lessWt: parseFloat(currentItem.lessWt) >= 0 ? currentItem.lessWt : '0',
      netWt: parseFloat(currentItem.netWt) > 0 ? currentItem.netWt : '0',
      tunch: parseFloat(currentItem.tunch) > 0 ? currentItem.tunch : '0',
      wstg: parseFloat(currentItem.wstg) >= 0 ? currentItem.wstg : '0',
      fine: parseFloat(currentItem.fine) > 0 ? currentItem.fine : '0'
    };

    setTableItems(newItems);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-6">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-40">Item Name</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-10">Pcs</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Gross Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Less Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Net Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Tunch</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Wstg</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-[100px]">Fine</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1 w-6">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1 w-40">
                <ItemSearch
                  selectedItem={{
                    itemId: item.itemId,
                    itemName: item.itemName,
                    type: ''
                  }}
                  items={items}
                  onItemSelect={(selectedItem) => handleItemSelect(index, selectedItem)}
                  onSearchChange={(searchTerm) => handleItemSearch(index, searchTerm)}
                />
              </td>
              <td className="border border-gray-300 px-1 py-1 w-10">
                <input
                  type="number"
                  value={item.pcs}
                  onChange={(e) => handleInputChange(index, 'pcs', e.target.value)}
                  className="w-full text-base"
                  min="0"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.grossWt}
                  onChange={(e) => handleInputChange(index, 'grossWt', e.target.value)}
                  className="w-full text-base"
                  step="0.001"
                  min="0"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.lessWt}
                  onChange={(e) => handleInputChange(index, 'lessWt', e.target.value)}
                  className="w-full text-base"
                  step="0.001"
                  min="0"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.netWt}
                  readOnly
                  className="w-full text-base bg-gray-100"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.tunch}
                  onChange={(e) => handleInputChange(index, 'tunch', e.target.value)}
                  className="w-full text-base"
                  step="0.01"
                  min="0"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.wstg}
                  onChange={(e) => handleInputChange(index, 'wstg', e.target.value)}
                  className="w-full text-base"
                  step="0.01"
                  min="0"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1 w-[100px]">
                <input
                  type="number"
                  value={item.fine}
                  readOnly
                  className="w-full text-base bg-gray-100"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default GoldItemsTable;
