import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface User {
    name: string;
    email: string;
    shopName?: string;
    shopAddress?: string;
    mobileNumber?: string;
}

// Separate interface for update data that includes password
interface UserUpdateData {
    name: string;
    email: string;
    shopName?: string;
    shopAddress?: string;
    mobileNumber?: string;
    password?: string;
}

interface AuthContextType {
    user: User | null;
    loading: boolean;
    login: (email: string, password: string) => Promise<boolean>;
    register: (name: string, email: string, password: string) => Promise<boolean>;
    logout: () => void;
    refreshToken: () => Promise<boolean>;
    updateProfile: (userData: UserUpdateData) => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

// Set the base URL for all axios requests using environment variable
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const initializeAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                setAuthToken(token);
                await loadUser();
            }
            setLoading(false);
        };
        initializeAuth();
    }, []);

    const setAuthToken = (token: string | null) => {
        if (token) {
            axios.defaults.headers.common['x-auth-token'] = token;
            localStorage.setItem('token', token);
        } else {
            delete axios.defaults.headers.common['x-auth-token'];
            localStorage.removeItem('token');
        }
    };

    const loadUser = async () => {
        try {
            const res = await axios.get('/api/auth');
            setUser(res.data);
        } catch (err) {
            console.error('Error loading user:', err);
            setUser(null);
            setAuthToken(null);
        }
    };

    const login = async (email: string, password: string) => {
        try {
            const res = await axios.post('/api/auth/login', { email, password });
            setAuthToken(res.data.token);
            await loadUser();
            return true;
        } catch (err) {
            console.error('Login error:', err);
            return false;
        }
    };

    const register = async (name: string, email: string, password: string) => {
        try {
            const res = await axios.post('/api/auth/register', { name, email, password });
            setAuthToken(res.data.token);
            await loadUser();
            return true;
        } catch (err) {
            console.error('Registration error:', err);
            return false;
        }
    };

    const updateProfile = async (userData: UserUpdateData) => {
        try {
            const res = await axios.put('/api/auth/profile', userData);
            setUser(res.data);
            return true;
        } catch (err) {
            console.error('Profile update error:', err);
            return false;
        }
    };

    const logout = () => {
        setAuthToken(null);
        setUser(null);
    };

    const refreshToken = async () => {
        try {
            const res = await axios.post('/api/auth/refresh-token');
            setAuthToken(res.data.token);
            await loadUser();
            return true;
        } catch (err) {
            console.error('Failed to refresh token:', err);
            logout();
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{ 
            user, 
            loading, 
            login, 
            register, 
            logout, 
            refreshToken,
            updateProfile 
        }}>
            {children}
        </AuthContext.Provider>
    );
};
