import React from 'react';

interface TotalsTableProps {
  outstandingGoldFine?: string;
  outstandingSilverFine?: string;
}

const TotalsTable: React.FC<TotalsTableProps> = ({
  outstandingGoldFine = '0',
  outstandingSilverFine = '0'
}) => {
  return (
    <div className="overflow-x-auto flex justify-end">
      <div className="w-auto">
        <div className="text-base font-bold mb-2">Totals</div>
        <table className="mb-4 border-collapse border border-gray-300">
          <tbody>
            <tr>
              <td className="border border-gray-300 px-3 py-1 font-bold text-base whitespace-nowrap">Outstanding Gold Fine</td>
              <td className="border border-gray-300 px-3 py-1">
                <input
                  type="number"
                  value={outstandingGoldFine}
                  readOnly
                  className="w-24 text-base bg-gray-100"
                />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-3 py-1 font-bold text-base whitespace-nowrap">Outstanding Silver Fine</td>
              <td className="border border-gray-300 px-3 py-1">
                <input
                  type="number"
                  value={outstandingSilverFine}
                  readOnly
                  className="w-24 text-base bg-gray-100"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TotalsTable;
