import axios from 'axios';
import { Loan, LoanResponse, LoansResponse } from '../types/loanTypes';

const API_URL = import.meta.env.VITE_API_URL;

interface VoucherNumberResponse {
  success: boolean;
  voucherNumber: number;
  message?: string;
}

export const createLoan = async (loanData: Omit<Loan, '_id'>): Promise<LoanResponse> => {
  try {
    const response = await axios.post(`${API_URL}/api/loans`, loanData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error creating loan');
    }
    throw error;
  }
};

export const updateLoan = async (id: string, loanData: Partial<Loan>): Promise<LoanResponse> => {
  try {
    const response = await axios.put(`${API_URL}/api/loans/${id}`, loanData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error updating loan');
    }
    throw error;
  }
};

export const getLoan = async (id: string): Promise<LoanResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans/${id}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error fetching loan');
    }
    throw error;
  }
};

export const getAllLoans = async (): Promise<LoansResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error fetching loans');
    }
    throw error;
  }
};

export const deleteLoan = async (id: string): Promise<LoanResponse> => {
  try {
    const response = await axios.delete(`${API_URL}/api/loans/${id}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error deleting loan');
    }
    throw error;
  }
};

export const getNextVoucherNumber = async (): Promise<VoucherNumberResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans/next-voucher-number`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error getting next voucher number');
    }
    throw error;
  }
};

export const getNearestPreviousLoan = async (voucherNumber: number): Promise<LoanResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans/previous/${voucherNumber}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error fetching previous loan');
    }
    throw error;
  }
};

export const getNearestNextLoan = async (voucherNumber: number): Promise<LoanResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans/next/${voucherNumber}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error fetching next loan');
    }
    throw error;
  }
};

export const searchLoans = async (query: string): Promise<LoansResponse> => {
  try {
    const response = await axios.get(`${API_URL}/api/loans/search?q=${query}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Error searching loans');
    }
    throw error;
  }
};
