import React, { useRef, useState, useEffect } from 'react';
import MoneyReceivedHeader from './MoneyReceivedHeader';
import MoneyReceivedTable from './MoneyReceivedTable';
import TotalsTable from './TotalsTable';
import NavigationButtons from './NavigationFooter';
import PreviousMoneyReceived from './PreviousMoneyReceived';
import { useSidebar } from '../../../contexts/SidebarContext';
import { 
  createMoneyReceived, 
  getMoneyReceived,
  fetchMoneyReceivedDetails,
  fetchNearestPreviousMoneyReceived,
  fetchNearestNextMoneyReceived
} from '../api/MoneyReceivedAPI';
import { MoneyReceived, CreateMoneyReceivedData } from '../types/moneyTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

interface MoneyReceivedFormProps {
  id?: string;
}

interface MoneyReceivedRow {
  amount: string;
  note: string;
}

interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
  outstandingAmount?: number;
}

const MoneyReceivedForm: React.FC<MoneyReceivedFormProps> = ({ id }) => {
  const { isOpen: isSidebarOpen } = useSidebar();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [customerName, setCustomerName] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [isCustomerSearchFocused, setIsCustomerSearchFocused] = useState(false);
  const [rows, setRows] = useState<MoneyReceivedRow[]>([{ amount: '', note: '' }]);
  const [date, setDate] = useState<Date>(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isViewingPrevious, setIsViewingPrevious] = useState(false);
  const [voucherNumber, setVoucherNumber] = useState<number>(1);
  const [previousOutstanding, setPreviousOutstanding] = useState<string>('0');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const voucherNumberRef = useRef<HTMLInputElement>(null);

  // Fetch customers
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/customers', {
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
        toast.error('Failed to fetch customers');
      }
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    const fetchLatestVoucherNumber = async () => {
      try {
        if (!id && !editingId) {
          const response = await getMoneyReceived();
          setVoucherNumber(response.nextVoucherNumber);
        }
      } catch (error) {
        console.error('Error fetching latest voucher number:', error);
        setError('Failed to fetch latest voucher number');
      }
    };

    fetchLatestVoucherNumber();
  }, [id, editingId]);

  useEffect(() => {
    if (id) {
      handleEdit(id);
    }
  }, [id]);

  const handleEdit = async (editId: string) => {
    try {
      setEditingId(editId);
      const entryData = await fetchMoneyReceivedDetails(editId);
      
      setDate(new Date(entryData.date));
      setVoucherNumber(entryData.voucherNumber);
      
      // Find the customer
      const customerId = typeof entryData.customer === 'string' ? entryData.customer : entryData.customer._id;
      const customer = customers.find(c => c._id === customerId);
      if (customer) {
        setSelectedCustomer(customer);
        setCustomerName(customer.name);
        setPreviousOutstanding(customer.outstandingAmount?.toString() || '0');
      }

      // Set the rows
      setRows([{
        amount: entryData.amount.toString(),
        note: entryData.note || ''
      }]);

    } catch (error) {
      console.error('Error loading entry for edit:', error);
      setError('Failed to load entry for editing');
      toast.error('Failed to load entry for editing');
    }
  };

  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
    setCustomerName(customer.name);
    setPreviousOutstanding(customer.outstandingAmount?.toString() || '0');
    setIsCustomerSearchFocused(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle key press events if needed
  };

  const validateEntry = (row: MoneyReceivedRow) => {
    if (!selectedCustomer?._id) {
      throw new Error('Please select a customer');
    }
    if (!row.amount || isNaN(Number(row.amount)) || Number(row.amount) <= 0) {
      throw new Error('Please enter a valid amount');
    }
    if (!date) {
      throw new Error('Please select a date');
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    try {
      // Validate all rows first
      rows.forEach((row, index) => {
        try {
          validateEntry(row);
        } catch (error) {
          throw new Error(`Row ${index + 1}: ${(error as Error).message}`);
        }
      });

      setIsSubmitting(true);
      setError(null);
      
      // Create entries for each row
      for (const row of rows) {
        const entryData: CreateMoneyReceivedData = {
          customer: selectedCustomer!._id,
          amount: Number(row.amount),
          date,
          note: row.note || undefined,
          voucherNumber
        };

        await createMoneyReceived(entryData);
      }
      
      toast.success('Money received successfully');
      // Reset form
      setSelectedCustomer(null);
      setCustomerName('');
      setRows([{ amount: '', note: '' }]);
      setDate(new Date());
      
      // Get new voucher number
      const response = await getMoneyReceived();
      setVoucherNumber(response.nextVoucherNumber);
      setEditingId(null);

    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        toast.error(error.message);
      } else if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        setError(errorMessage);
        toast.error(`Error: ${errorMessage}`);
      } else {
        setError('Failed to record money received');
        toast.error('Failed to record money received');
      }
      console.error('Error details:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrevious = async () => {
    try {
      const previousEntry = await fetchNearestPreviousMoneyReceived(voucherNumber);
      if (previousEntry && previousEntry._id) {
        await handleEdit(previousEntry._id);
        toast.success('Previous entry loaded');
      } else {
        toast.info('No previous entries found');
      }
    } catch (error) {
      console.error('Error fetching previous entry:', error);
      toast.error('Failed to fetch previous entry');
    }
  };

  const handleNext = async () => {
    try {
      const nextEntry = await fetchNearestNextMoneyReceived(voucherNumber);
      if (nextEntry && nextEntry._id) {
        await handleEdit(nextEntry._id);
        toast.success('Next entry loaded');
      } else {
        // Reset form for new entry
        setSelectedCustomer(null);
        setCustomerName('');
        setRows([{ amount: '', note: '' }]);
        setDate(new Date());
        setEditingId(null);
        
        // Get new voucher number
        const response = await getMoneyReceived();
        setVoucherNumber(response.nextVoucherNumber);
        toast.info('Ready for new entry');
      }
    } catch (error) {
      console.error('Error fetching next entry:', error);
      toast.error('Failed to fetch next entry');
    }
  };

  const totalAmount = rows.reduce((sum, row) => sum + (Number(row.amount) || 0), 0).toString();

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      <MoneyReceivedHeader
        customerName={customerName}
        setCustomerName={setCustomerName}
        customers={customers}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        isCustomerSearchFocused={isCustomerSearchFocused}
        setIsCustomerSearchFocused={setIsCustomerSearchFocused}
        handleCustomerSelect={handleCustomerSelect}
        setPreviousOutstanding={setPreviousOutstanding}
        setSelectedCustomer={setSelectedCustomer}
        previousOutstanding={Number(previousOutstanding)}
        handleKeyPress={handleKeyPress}
        voucherNumberRef={voucherNumberRef}
        date={date}
        setDate={setDate}
        voucherNumber={voucherNumber}
      />

      <MoneyReceivedTable
        rows={rows}
        setRows={setRows}
      />

      <TotalsTable totalMoney={totalAmount} />

      <NavigationButtons
        onPrevious={handlePrevious}
        onNext={handleNext}
        onViewPrevious={() => setIsViewingPrevious(true)}
        onSave={handleSubmit}
        isSidebarOpen={isSidebarOpen}
        voucherNumber={voucherNumber}
        editingId={editingId}
      />

      <PreviousMoneyReceived
        isOpen={isViewingPrevious}
        onClose={() => setIsViewingPrevious(false)}
      />
    </div>
  );
};

export default MoneyReceivedForm;
