import { Bill, Customer } from '../types/bill';

export const printBill = (bill: Bill, customer: Customer) => {
  const printWindow = window.open('', '_blank');
  if (!printWindow) {
    alert('Please allow popups for printing bills');
    return;
  }

  const content = generatePrintContent(bill, customer);
  printWindow.document.open();
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.print();
};

export const handlePrintClick = (bill: Bill, customer: Customer) => {
  printBill(bill, customer);
};

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(amount);
};

export const formatDate = (date: Date): string => {
  return date.toLocaleDateString('en-IN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

export const generateBillNumber = (number: number): string => {
  return number.toString().padStart(6, '0');
};

const generatePrintContent = (bill: Bill, customer: Customer): string => {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Bill #${bill.voucherNumber}</title>
        <style>
          ${getPrintStyles()}
        </style>
      </head>
      <body>
        <div class="bill-content">
          ${getBillHeader(bill)}
          ${getCustomerInfo(customer)}
          ${getBillItems(bill)}
          ${getBillTotals(bill)}
        </div>
      </body>
    </html>
  `;
};

const getPrintStyles = (): string => {
  return `
    body { font-family: Arial, sans-serif; }
    .bill-content { max-width: 800px; margin: 0 auto; padding: 20px; }
    table { width: 100%; border-collapse: collapse; margin: 10px 0; }
    th, td { border: 1px solid black; padding: 5px; }
    th { background-color: #f0f0f0; }
    .text-right { text-align: right; }
    .total-row { font-weight: bold; }
  `;
};

const getBillHeader = (bill: Bill): string => {
  return `
    <div class="bill-header">
      <h1>Bill #${bill.voucherNumber}</h1>
      <p>Date: ${formatDate(bill.date)}</p>
    </div>
  `;
};

const getCustomerInfo = (customer: Customer): string => {
  return `
    <div class="customer-info">
      <h2>Customer Details</h2>
      <table>
        <tr>
          <td><strong>Name:</strong></td>
          <td>${customer.name}</td>
          <td><strong>Phone:</strong></td>
          <td>${customer.phone || 'N/A'}</td>
        </tr>
        <tr>
          <td><strong>Address:</strong></td>
          <td>${customer.address || 'N/A'}</td>
          <td><strong>City:</strong></td>
          <td>${customer.city || 'N/A'}</td>
        </tr>
      </table>
    </div>
  `;
};

const getBillItems = (bill: Bill): string => {
  return `
    <table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Rate</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        ${bill.items.map(item => `
          <tr>
            <td>${item.itemNameWithType}</td>
            <td class="text-right">${item.pcs}</td>
            <td class="text-right">${formatCurrency(Number(item.rate) || 0)}</td>
            <td class="text-right">${formatCurrency(item.amt)}</td>
          </tr>
        `).join('')}
      </tbody>
    </table>
  `;
};

const getBillTotals = (bill: Bill): string => {
  return `
    <table class="totals-table">
      <tr>
        <td>Total Amount:</td>
        <td class="text-right">${formatCurrency(bill.totalAmount || 0)}</td>
      </tr>
      ${bill.taxAmount ? `
        <tr>
          <td>Tax Amount:</td>
          <td class="text-right">${formatCurrency(bill.taxAmount)}</td>
        </tr>
      ` : ''}
      <tr class="total-row">
        <td>Net Amount:</td>
        <td class="text-right">${formatCurrency(bill.osAmount || 0)}</td>
      </tr>
    </table>
  `;
};
