import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { createLoan, updateLoan, deleteLoan, getLoan, getNextVoucherNumber } from '../api/LoanAPI';
import { Item } from '../../../shared/ItemSearch/types/item';
import { Loan, LoanItem, LoanDetail, Customer } from '../types/loanTypes';
import axios from 'axios';

interface UseLoanFormReturn {
  loanState: Loan;
  setLoanState: React.Dispatch<React.SetStateAction<Loan>>;
  customers: Customer[];
  filteredCustomers: Customer[];
  setFilteredCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  items: Item[];
  isCustomerSearchFocused: boolean;
  setIsCustomerSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleCustomerSelect: (customer: Customer) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  handleItemChange: (items: LoanItem[]) => void;
  handleLoanDetailChange: (details: LoanDetail[]) => void;
  isLoading: boolean;
  error: string | null;
}

interface UseLoanFormProps {
  loanId?: string;
}

const initializeNewLoan = (voucherNumber: number = 1): Loan => ({
  date: new Date(),
  customer: '',
  voucherNumber,
  items: [{
    itemId: '',
    itemName: '',
    type: '',
    pcs: '0',
    grossWt: '0',
    lessWt: '0',
    netWt: '0',
    tunch: '0',
    wstg: '0',
    fine: '0',
    rate: '0',
    amt: '0'
  }],
  loanDetails: [{
    amt: '0',
    monthlyInt: '0',
    note: ''
  }],
  totalAmount: 0
});

const API_URL = import.meta.env.VITE_API_URL;

const fetchCustomersData = async (
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
  setFilteredCustomers: React.Dispatch<React.SetStateAction<Customer[]>>
) => {
  try {
    const response = await axios.get(`${API_URL}/api/customers`);
    setCustomers(response.data);
    setFilteredCustomers(response.data);
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

const fetchItemsData = async (setItems: React.Dispatch<React.SetStateAction<Item[]>>) => {
  try {
    const response = await axios.get(`${API_URL}/api/items`);
    setItems(response.data);
  } catch (error) {
    console.error('Error fetching items:', error);
  }
};

export const useLoanForm = ({ loanId }: UseLoanFormProps = {}): UseLoanFormReturn => {
  const { user, loading } = useContext(AuthContext) ?? { user: null, loading: true };
  const navigate = useNavigate();

  const [loanState, setLoanState] = useState<Loan>(initializeNewLoan());
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [isCustomerSearchFocused, setIsCustomerSearchFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [nextVoucherNumber, setNextVoucherNumber] = useState(1);

  const initialFetchDone = useRef(false);

  // Load specific loan if loanId is provided
  useEffect(() => {
    if (loanId && !initialFetchDone.current) {
      getLoan(loanId).then((response) => {
        if (response.success && response.data) {
          setLoanState(response.data);
          initialFetchDone.current = true;
        }
      });
    }
  }, [loanId]);

  // Initialize with next voucher number and fetch data
  useEffect(() => {
    const fetchInitialData = async () => {
      if (!initialFetchDone.current && !loanId) {
        try {
          const [voucherResponse] = await Promise.all([
            getNextVoucherNumber(),
            fetchCustomersData(setCustomers, setFilteredCustomers),
            fetchItemsData(setItems)
          ]);

          if (voucherResponse.success) {
            setNextVoucherNumber(voucherResponse.voucherNumber);
            setLoanState(initializeNewLoan(voucherResponse.voucherNumber));
          }
          initialFetchDone.current = true;
        } catch (error) {
          console.error('Error fetching initial data:', error);
        }
      }
    };

    fetchInitialData();
  }, [loanId]);

  const calculateTotalAmount = useCallback((items: LoanItem[], details: LoanDetail[]) => {
    const itemsTotal = items.reduce((sum, item) => sum + (parseFloat(item.amt) || 0), 0);
    const detailsTotal = details.reduce((sum, detail) => sum + (parseFloat(detail.amt) || 0), 0);
    return itemsTotal + detailsTotal;
  }, []);

  const handleCustomerSelect = (customer: Customer) => {
    setLoanState(prev => ({
      ...prev,
      customer: customer._id,
      selectedCustomer: customer._id
    }));
    setIsCustomerSearchFocused(false);
  };

  const handleItemChange = (updatedItems: LoanItem[]) => {
    setLoanState(prev => {
      const totalAmount = calculateTotalAmount(updatedItems, prev.loanDetails);
      return { ...prev, items: updatedItems, totalAmount };
    });
  };

  const handleLoanDetailChange = (updatedDetails: LoanDetail[]) => {
    setLoanState(prev => {
      const totalAmount = calculateTotalAmount(prev.items, updatedDetails);
      return { ...prev, loanDetails: updatedDetails, totalAmount };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!loanState.selectedCustomer && typeof loanState.customer !== 'string') {
      setError('Please select a customer');
      setIsLoading(false);
      return;
    }

    try {
      const loanData = {
        ...loanState,
        customer: loanState.selectedCustomer || (typeof loanState.customer === 'string' ? loanState.customer : loanState.customer._id),
        date: loanState.date || new Date(),
        voucherNumber: loanId ? loanState.voucherNumber : nextVoucherNumber
      };

      if (loanId) {
        await updateLoan(loanId, loanData);
      } else {
        const { _id, ...newLoanData } = loanData;
        await createLoan(newLoanData);
        setNextVoucherNumber(prev => prev + 1);
        setLoanState(initializeNewLoan(nextVoucherNumber + 1));
      }

      setIsCustomerSearchFocused(false);
      navigate('/loan-issue');
    } catch (err: any) {
      console.error('Error details:', err.response?.data);
      setError('Failed to save loan: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    loanState,
    setLoanState,
    customers,
    filteredCustomers,
    setFilteredCustomers,
    items,
    isCustomerSearchFocused,
    setIsCustomerSearchFocused,
    handleCustomerSelect,
    handleSubmit,
    handleItemChange,
    handleLoanDetailChange,
    isLoading,
    error
  };
};
