import axios from 'axios';
import { MoneyReceived, CreateMoneyReceivedData } from '../types/moneyTypes';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

// Create axios instance with default headers
const api = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface MoneyReceivedResponse {
  entries: MoneyReceived[];
  nextVoucherNumber: number;
}

export const createMoneyReceived = async (entry: CreateMoneyReceivedData) => {
  try {
    console.log('Creating money received entry:', entry);
    const response = await api.post('/money-received', entry);
    return response.data as MoneyReceived;
  } catch (error) {
    console.error('API Error:', error);
    if (axios.isAxiosError(error) && error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const getMoneyReceived = async (): Promise<MoneyReceivedResponse> => {
  try {
    const response = await api.get('/money-received');
    return response.data;
  } catch (error) {
    console.error('Error fetching money received entries:', error);
    throw error;
  }
};

export const fetchMoneyReceivedDetails = async (id: string) => {
  try {
    const response = await api.get(`/money-received/${id}`);
    return response.data as MoneyReceived;
  } catch (error) {
    console.error('Error fetching money received details:', error);
    throw error;
  }
};

export const fetchNearestPreviousMoneyReceived = async (currentVoucherNumber: number) => {
  try {
    const response = await api.get(`/money-received/previous/${currentVoucherNumber}`);
    return response.data as MoneyReceived;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    console.error('Error fetching previous money received:', error);
    throw error;
  }
};

export const fetchNearestNextMoneyReceived = async (currentVoucherNumber: number) => {
  try {
    const response = await api.get(`/money-received/next/${currentVoucherNumber}`);
    return response.data as MoneyReceived;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    console.error('Error fetching next money received:', error);
    throw error;
  }
};

export const updateMoneyReceived = async (id: string, entry: Partial<CreateMoneyReceivedData>) => {
  try {
    const response = await api.put(`/money-received/${id}`, entry);
    return response.data as MoneyReceived;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const deleteMoneyReceived = async (id: string) => {
  try {
    const response = await api.delete(`/money-received/${id}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};
