import React, { useCallback } from 'react';
import { useBillForm } from '../../hooks/useBillForm';
import { BillHeader } from './BillHeader';
import { BillItems } from './BillItems';
import { OldItemsTable } from './OldItemsTable';
import { TotalsTable } from './TotalsTable';
import { NavigationFooter } from './NavigationFooter';
import { PreviousBills } from './PreviousBills';
import { Customer } from '../../types/bill';
import { handlePrintClick } from '../../utils/printUtils';
import { useBillContext, billActions } from '../../contexts/BillContext';
import { customerApi } from '../../api/customerApi';
import { billApi } from '../../api/billApi';
import { BillItemStringFields, OldItemStringFields } from '../../hooks/useBillForm';

interface BillFormProps {
  billId?: string;
}

export const BillForm: React.FC<BillFormProps> = ({ billId }) => {
  const { state, dispatch } = useBillContext();
  const initialLoadRef = React.useRef(false);

  // Load initial data when component mounts
  React.useEffect(() => {
    let mounted = true;

    const loadInitialData = async () => {
      if (initialLoadRef.current) return;

      try {
        dispatch(billActions.setLoading(true));
        dispatch(billActions.setError(null));
        
        // Load customers and bills in parallel
        const [customers, billsData] = await Promise.all([
          customerApi.getCustomers(),
          billApi.fetchBills()
        ]);
        
        if (!mounted) return;

        if (Array.isArray(customers) && customers.length > 0) {
          dispatch(billActions.setCustomers(customers));
          dispatch(billActions.setFilteredCustomers(customers));
        } else {
          dispatch(billActions.setError('No customers found'));
        }

        // Set the next voucher number from bills data
        if (!billId && billsData.nextVoucherNumber) {
          dispatch(billActions.setBill({
            ...state.bill,
            voucherNumber: billsData.nextVoucherNumber
          }));
        }

        initialLoadRef.current = true;
      } catch (error) {
        if (mounted) {
          dispatch(billActions.setError(error instanceof Error ? error.message : 'Failed to load initial data'));
        }
      } finally {
        if (mounted) {
          dispatch(billActions.setLoading(false));
        }
      }
    };

    // Only load if we haven't loaded customers or need a voucher number
    if (!state.customers.length || (!billId && state.bill.voucherNumber === 1)) {
      loadInitialData();
    }

    return () => {
      mounted = false;
    };
  }, [dispatch, state.customers.length, state.bill, billId]);

  const {
    bill,
    isLoading: formLoading,
    error: formError,
    handleCustomerSelect,
    handleBillItemChange,
    handleBillItemSelect,
    handleOldItemChange,
    handleOldItemSelect,
    handleSubmit,
    handleDateChange,
    addBillItem,
    addOldItem,
    handlePreviousBill,
    handleNextBill,
    handleViewPreviousBills
  } = useBillForm(billId);

  const onCustomerSelect = useCallback((customer: Customer) => {
    handleCustomerSelect(customer);
  }, [handleCustomerSelect]);

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await handleSubmit();
    } catch (error) {
      // Error handling is done in useBillForm
    }
  }, [handleSubmit]);

  const onDateChange = useCallback((date: Date) => {
    handleDateChange(date);
  }, [handleDateChange]);

  const onPrint = useCallback(() => {
    if (bill.customer) {
      const customer = state.customers.find(c => c._id === bill.customer);
      if (customer) {
        handlePrintClick(bill, customer);
      }
    }
  }, [bill, state.customers]);

  const handleEdit = useCallback(async (editBillId: string) => {
    try {
      dispatch(billActions.setLoading(true));
      dispatch(billActions.setError(null));
      
      const billData = await billApi.fetchBillDetails(editBillId);
      dispatch(billActions.setBill({
        ...billData,
        date: new Date(billData.date)
      }));
    } catch (error) {
      dispatch(billActions.setError(error instanceof Error ? error.message : 'Failed to load bill'));
    } finally {
      dispatch(billActions.setLoading(false));
    }
  }, [dispatch]);

  const isLoading = state.isLoading || formLoading;
  const error = state.error || formError;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-6">
        <BillHeader
          date={bill.date}
          voucherNumber={bill.voucherNumber}
          onCustomerSelect={onCustomerSelect}
          onDateChange={onDateChange}
          customerName={bill.selectedCustomer}
          previousOutstanding={bill.previousOutstanding}
        />

        <BillItems
          items={bill.items}
          onItemChange={handleBillItemChange}
          onItemSelect={handleBillItemSelect}
          onAddItem={addBillItem}
        />

        <div className="flex gap-4">
          <div className="flex-1">
            <OldItemsTable
              items={bill.oldGoldSilverItems || []}
              onItemChange={handleOldItemChange}
              onItemSelect={handleOldItemSelect}
              onAddItem={addOldItem}
            />
          </div>
          <div className="flex-1">
            <TotalsTable bill={bill} />
          </div>
        </div>

        <NavigationFooter
          bill={bill}
          billId={billId}
          onPrint={onPrint}
          onPreviousBill={handlePreviousBill}
          onNextBill={handleNextBill}
          onViewPreviousBills={handleViewPreviousBills}
          onSubmit={onSubmit}
        />
      </form>

      <PreviousBills onEdit={handleEdit} />
    </>
  );
};
