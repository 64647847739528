import React, { useRef, useContext } from 'react';
import { 
  createVendorBill,
  updateVendorBill,
  fetchVendorBillDetails,
  fetchNearestPreviousVendorBill,
  fetchNearestNextVendorBill,
  getVendorBills,
} from '../api/VendorBillAPI';
import {
  GoldItemsTable,
  SilverItemsTable,
  TotalsTable,
  VendorBillFormHeader
} from '.';
import { useVendorBillForm } from '../hooks/useVendorBillForm';
import { AuthContext } from '../../../contexts/AuthContext';

interface VendorBillFormProps {
  billId?: string;
}

const VendorBillForm: React.FC<VendorBillFormProps> = ({ billId }) => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('AuthContext not found');
  }

  const {
    date,
    setDate,
    voucherNumber,
    setVoucherNumber,
    selectedVendor,
    setSelectedVendor,
    goldItems,
    setGoldItems,
    silverItems,
    setSilverItems,
    outstandingGoldFine,
    outstandingSilverFine,
    items,
    isSubmitting,
    setIsSubmitting,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    validateForm,
    resetForm
  } = useVendorBillForm({ billId });

  const [editingBillId, setEditingBillId] = React.useState<string | null>(null);
  const [isPreviousBillsOpen, setIsPreviousBillsOpen] = React.useState(false);
  const voucherNumberRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const loadInitialData = async () => {
      if (!auth.loading && auth.user) {
        try {
          const billsData = await getVendorBills();
          if (!billId && !editingBillId) {
            setVoucherNumber(billsData.nextVoucherNumber);
          }
        } catch (error) {
          console.error('Error loading initial data:', error);
          setError('Failed to load initial data');
        }
      }
    };
    
    loadInitialData();
  }, [billId, editingBillId, setVoucherNumber, setError, auth.loading, auth.user]);

  React.useEffect(() => {
    if (billId && auth.user) {
      handleEdit(billId);
    }
  }, [billId, auth.user]);

  const handleEdit = async (editBillId: string) => {
    try {
      setEditingBillId(editBillId);
      const billData = await fetchVendorBillDetails(editBillId);
      
      setDate(new Date(billData.date));
      setVoucherNumber(billData.voucherNumber);
      setSelectedVendor({
        _id: billData.vendor,
        name: billData.selectedVendor
      });
      setGoldItems(billData.goldItems);
      setSilverItems(billData.silverItems);
    } catch (error) {
      console.error('Error loading bill for edit:', error);
      setError('Failed to load bill for editing');
    }
  };

  const handlePreviousBill = async () => {
    if (voucherNumber > 1) {
      try {
        const previousBill = await fetchNearestPreviousVendorBill(voucherNumber);
        if (previousBill && previousBill._id) {
          handleEdit(previousBill._id);
        }
      } catch (err) {
        console.error('Error fetching previous bill:', err);
        setError('Failed to fetch previous bill');
      }
    }
  };

  const handleNextBill = async () => {
    try {
      const nextBill = await fetchNearestNextVendorBill(voucherNumber);
      if (nextBill && nextBill._id) {
        handleEdit(nextBill._id);
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error('Error fetching next bill:', err);
      window.location.reload();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    // Ensure we have a valid date
    if (!date) {
      setError('Please select a valid date');
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const vendorBillData = {
        vendor: selectedVendor!._id,
        selectedVendor: selectedVendor!.name,
        date: date,
        goldItems,
        silverItems,
        outstandingGoldFine,
        outstandingSilverFine
      };

      if (editingBillId) {
        await updateVendorBill(editingBillId, { ...vendorBillData, voucherNumber });
        setSuccessMessage('Vendor bill updated successfully');
      } else {
        await createVendorBill(vendorBillData);
        setSuccessMessage('Vendor bill saved successfully');
      }

      resetForm();
      const billsData = await getVendorBills();
      setVoucherNumber(billsData.nextVoucherNumber);
    } catch (error) {
      console.error('Error saving vendor bill:', error);
      setError('Failed to save vendor bill');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  if (!auth.user) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          {successMessage}
        </div>
      )}

      <VendorBillFormHeader
        selectedVendor={selectedVendor}
        onVendorSelect={setSelectedVendor}
        date={date}
        setDate={setDate}
        voucherNumber={voucherNumber}
      />

      <div className="mb-4">
        <h2 className="text-base font-bold mb-2">Gold Items</h2>
        <GoldItemsTable 
          items={items}
          tableItems={goldItems}
          setTableItems={setGoldItems}
        />
      </div>

      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-2/3 px-3">
          <h2 className="text-base font-bold mb-2">Silver Items</h2>
          <SilverItemsTable 
            tableItems={silverItems}
            setTableItems={setSilverItems}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <TotalsTable
            outstandingGoldFine={outstandingGoldFine}
            outstandingSilverFine={outstandingSilverFine}
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <div className="flex items-center space-x-4">
          {voucherNumber > 1 && (
            <button
              type="button"
              onClick={handlePreviousBill}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              title="Previous Bill"
            >
              &lt;
            </button>
          )}
          <button
            type="button"
            onClick={() => setIsPreviousBillsOpen(true)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            View Previous Bills
          </button>
          <button
            type="button"
            onClick={handleNextBill}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            title={voucherNumber ? "Next Bill" : "Create New Bill"}
          >
            &gt;
          </button>
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`${
            isSubmitting ? 'bg-blue-300' : 'bg-blue-500 hover:bg-blue-700'
          } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
        >
          {isSubmitting ? 'Saving...' : (editingBillId ? 'Update Bill' : 'Save Bill')}
        </button>
      </div>
    </form>
  );
};

export default VendorBillForm;
