import React from 'react';

interface NavigationButtonsProps {
  onPrevious: () => void;
  onNext: () => void;
  onViewPrevious: () => void;
  onSave: () => void;
  isSidebarOpen?: boolean;
  voucherNumber?: number;
  editingId?: string | null;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  onPrevious,
  onNext,
  onViewPrevious,
  onSave,
  isSidebarOpen = true,
  voucherNumber,
  editingId
}) => {
  return (
    <div 
      className={`
        fixed bottom-0 bg-white shadow-lg p-4 flex items-center justify-between transition-all duration-300
        ${isSidebarOpen ? 'left-64' : 'left-0'}
      `}
      style={{
        width: isSidebarOpen ? 'calc(100% - 256px)' : '100%',
        zIndex: 50,
        transitionProperty: 'all',
        transitionDuration: '300ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
      }}
    >
      <div className="flex items-center space-x-4">
        {(!voucherNumber || voucherNumber > 1) && (
          <button
            type="button"
            onClick={onPrevious}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            title="Previous Bill"
          >
            &lt;
          </button>
        )}
        <button
          type="button"
          onClick={onViewPrevious}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          View Previous Bills
        </button>
        <button
          type="button"
          onClick={onNext}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          title={voucherNumber ? "Next Bill" : "Create New Bill"}
        >
          &gt;
        </button>
      </div>
      <div className="flex items-center space-x-4">
        <button
          onClick={onSave}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {editingId ? 'Update' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
