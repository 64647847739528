import React, { useState, useEffect } from 'react';
import { fetchVendorReport, VendorReportEntry } from '../features/vendorReport/VendorReportAPI';
import VendorStatementPopup from '../features/vendorReport/VendorStatementPopup';

const VendorReport: React.FC = () => {
  const [vendorData, setVendorData] = useState<VendorReportEntry[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<VendorReportEntry | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadVendorReport();
  }, []);

  const loadVendorReport = async () => {
    try {
      setLoading(true);
      const data = await fetchVendorReport();
      setVendorData(data);
      setError(null);
    } catch (err) {
      setError('Failed to load vendor report');
      console.error('Error loading vendor report:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleShowDetails = (vendor: VendorReportEntry) => {
    setSelectedVendor(vendor);
    setIsPopupOpen(true);
  };

  // Calculate totals
  const totalGoldOutstanding = vendorData.reduce(
    (sum, vendor) => sum + parseFloat(vendor.goldSummary.outstandingFine || '0'),
    0
  );

  const totalSilverOutstanding = vendorData.reduce(
    (sum, vendor) => sum + parseFloat(vendor.silverSummary.outstandingFine || '0'),
    0
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-xl text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Vendor Report</h1>
          <div className="space-x-4">
            <div className="text-xl font-semibold text-yellow-600">
              Total Gold Outstanding: {totalGoldOutstanding.toFixed(3)}
            </div>
            <div className="text-xl font-semibold text-gray-600">
              Total Silver Outstanding: {totalSilverOutstanding.toFixed(3)}
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Vendor Name
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Gold Outstanding
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Silver Outstanding
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {vendorData.map((vendor) => (
                <tr key={vendor._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div className="text-sm leading-5 font-medium text-gray-900">
                      {vendor.vendorName}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                    <div className="text-sm leading-5 font-medium text-yellow-600">
                      {parseFloat(vendor.goldSummary.outstandingFine).toFixed(3)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                    <div className="text-sm leading-5 font-medium text-gray-600">
                      {parseFloat(vendor.silverSummary.outstandingFine).toFixed(3)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                    <button
                      onClick={() => handleShowDetails(vendor)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Show Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedVendor && (
        <VendorStatementPopup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedVendor(null);
          }}
          vendor={selectedVendor}
        />
      )}
    </div>
  );
};

export default VendorReport;
