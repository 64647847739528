import React from 'react';
import { Bill } from '../../types/bill';
import { useBillContext } from '../../contexts/BillContext';

interface NavigationFooterProps {
  bill: Bill;
  billId?: string;
  onPrint?: () => void;
  onPreviousBill?: () => void;
  onNextBill?: () => void;
  onViewPreviousBills?: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
}

export const NavigationFooter: React.FC<NavigationFooterProps> = ({
  bill,
  billId,
  onPrint,
  onPreviousBill,
  onNextBill,
  onViewPreviousBills,
  onSubmit
}) => {
  const { state } = useBillContext();
  const { isLoading } = state;

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 bg-white shadow-lg px-4 py-2 flex items-center justify-between border-t border-gray-300"
      style={{
        width: '100%',
        zIndex: 50,
      }}
    >
      <div className="flex items-center gap-4">
        {/* Only show previous button if there's a voucher number and it's greater than 1 */}
        {bill.voucherNumber && bill.voucherNumber > 1 && (
          <button
            type="button"
            onClick={onPreviousBill}
            disabled={isLoading}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
            title="Previous Bill"
          >
            &lt;
          </button>
        )}
        
        <button
          type="button"
          onClick={onViewPreviousBills}
          disabled={isLoading}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          View Previous Bills
        </button>
        
        {/* Only show next button if there's a voucher number */}
        {bill.voucherNumber && (
          <button
            type="button"
            onClick={onNextBill}
            disabled={isLoading}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
            title="Next Bill"
          >
            &gt;
          </button>
        )}
      </div>

      <div className="flex items-center gap-4">
        {bill.customer && (
          <button
            type="button"
            onClick={onPrint}
            disabled={isLoading}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            Print
          </button>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          {isLoading ? (
            <span className="flex items-center gap-2">
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : billId ? 'Update' : 'Save'}
        </button>
      </div>
    </div>
  );
};
