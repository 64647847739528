import React, { useRef, useEffect } from 'react';

interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
}

interface CustomerSearchProps {
  customerName: string;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  customers: Customer[];
  filteredCustomers: Customer[];
  setFilteredCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  isCustomerSearchFocused: boolean;
  setIsCustomerSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleCustomerSelect: (customer: Customer) => void;
  setPreviousOutstanding: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  previousOutstanding: number;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>, voucherNumberRef: React.RefObject<HTMLInputElement>) => void;
  voucherNumberRef: React.RefObject<HTMLInputElement>;
}

const CustomerSearch: React.FC<CustomerSearchProps> = ({
  customerName,
  setCustomerName,
  customers,
  filteredCustomers,
  setFilteredCustomers,
  isCustomerSearchFocused,
  setIsCustomerSearchFocused,
  handleCustomerSelect,
  setPreviousOutstanding,
  setSelectedCustomer,
  previousOutstanding,
  handleKeyPress,
  voucherNumberRef
}) => {
  const customerSearchRef = useRef<HTMLDivElement>(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (customerSearchRef.current && !customerSearchRef.current.contains(event.target as Node)) {
        setIsCustomerSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsCustomerSearchFocused]);

  // Handle customer search input
  const handleCustomerSearch = (value: string) => {
    setCustomerName(value);
    
    // Don't clear previous data if we're just focusing with an existing customer
    if (!value) {
      setIsCustomerSearchFocused(true);
      return;
    }

    setIsCustomerSearchFocused(true);

    // Only filter if we have customers and a search value
    if (customers?.length) {
      const filtered = customers.filter(customer =>
        customer.name.toLowerCase().includes(value.toLowerCase()) ||
        (customer.phone && customer.phone.includes(value)) ||
        (customer.address && customer.address.toLowerCase().includes(value.toLowerCase())) ||
        (customer.city && customer.city.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers([]);
    }
  };

  // Handle customer selection
  const onCustomerSelect = (customer: Customer) => {
    setCustomerName(customer.name);
    handleCustomerSelect(customer);
    setSelectedCustomer(customer);
    setIsCustomerSearchFocused(false);
  };

  return (
    <div className="mb-4 relative" ref={customerSearchRef}>
      <label className="block text-gray-700 font-bold mb-2" htmlFor="customerName">
        Customer Name
        <span className="text-sm text-gray-600 mt-1 ml-5">
          [Previous outstanding: {previousOutstanding || '0'}]
        </span>
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="customerName"
        type="text"
        placeholder="Search here"
        value={customerName}
        onChange={(e) => handleCustomerSearch(e.target.value)}
        onFocus={() => {
          setIsCustomerSearchFocused(true);
          // If there's a customer name but no filtered customers, filter based on the current name
          if (customerName && filteredCustomers.length === 0) {
            handleCustomerSearch(customerName);
          }
        }}
        onKeyPress={(e) => handleKeyPress(e, voucherNumberRef)}
      />

      {isCustomerSearchFocused && filteredCustomers.length > 0 && (
        <div className="absolute z-10 bg-white border border-gray-300 mt-1 w-full shadow-lg rounded-md overflow-hidden max-h-60 overflow-y-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Phone</th>
                <th className="px-4 py-2 text-left">Address</th>
                <th className="px-4 py-2 text-left">City</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer) => (
                <tr
                  key={customer._id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => onCustomerSelect(customer)}
                >
                  <td className="px-4 py-2">{customer.name}</td>
                  <td className="px-4 py-2">{customer.phone || 'N/A'}</td>
                  <td className="px-4 py-2">{customer.address || 'N/A'}</td>
                  <td className="px-4 py-2">{customer.city || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomerSearch;
