import axios, { AxiosError } from 'axios';
import { Bill, BillItem, OldGoldSilverItem } from '../types/bill';

interface ApiResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

interface CreateBillResponse {
  bill: Bill;
  nextVoucherNumber: number;
}

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Simplified error handler with production-safe error messages
const handleApiError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<{ error?: string; details?: any }>;
    
    // Handle specific error cases with user-friendly messages
    if (axiosError.response?.status === 401) {
      throw new Error('Your session has expired. Please log in again.');
    }
    if (axiosError.response?.status === 404) {
      if (axiosError.config?.url?.includes('nearest-previous')) {
        throw new Error('No previous bill found');
      }
      if (axiosError.config?.url?.includes('nearest-next')) {
        throw new Error('No next bill found');
      }
      throw new Error('The requested resource was not found.');
    }
    if (axiosError.response?.status === 500) {
      throw new Error('An error occurred while processing your request. Please try again.');
    }

    // Use server error message if available, otherwise fallback to generic message
    throw new Error(axiosError.response?.data?.error || 'An unexpected error occurred');
  }
  throw error;
};

export const billApi = {
  createBill: async (billData: Omit<Bill, '_id'>): Promise<CreateBillResponse> => {
    try {
      const response = await api.post<ApiResponse<CreateBillResponse>>('/customerBills2', billData);
      if (!response.data.success) {
        throw new Error('Failed to create bill');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchBills: async (): Promise<{ bills: Bill[]; nextVoucherNumber: number }> => {
    try {
      const response = await api.get<ApiResponse<{
        bills: Bill[];
        nextVoucherNumber: number;
        pagination: {
          total: number;
          page: number;
          pages: number;
        }
      }>>('/customerBills2');
      if (!response.data.success) {
        throw new Error('Failed to fetch bills');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchBillByVoucherNumber: async (voucherNumber: number): Promise<Bill> => {
    try {
      const response = await api.get<ApiResponse<Bill>>(
        `/customerBills2/search/by-voucher/${voucherNumber}`
      );
      if (!response.data.success) {
        throw new Error('Failed to fetch bill');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchBillDetails: async (billId: string): Promise<Bill> => {
    try {
      const response = await api.get<ApiResponse<Bill>>(`/customerBills2/${billId}`);
      if (!response.data.success) {
        throw new Error('Failed to fetch bill details');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  updateBill: async (billId: string, billData: Partial<Bill>): Promise<Bill> => {
    try {
      const response = await api.put<ApiResponse<Bill>>(
        `/customerBills2/${billId}`,
        billData
      );
      if (!response.data.success) {
        throw new Error('Failed to update bill');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  deleteBill: async (billId: string): Promise<void> => {
    try {
      const response = await api.delete<ApiResponse<void>>(`/customerBills2/${billId}`);
      if (!response.data.success) {
        throw new Error('Failed to delete bill');
      }
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchNearestPreviousBill: async (voucherNumber: number): Promise<Bill> => {
    try {
      const response = await api.get<ApiResponse<Bill>>(
        `/customerBills2/search/nearest-previous/${voucherNumber}`
      );
      if (!response.data.success) {
        throw new Error('No previous bill found');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchNearestNextBill: async (voucherNumber: number): Promise<Bill> => {
    try {
      const response = await api.get<ApiResponse<Bill>>(
        `/customerBills2/search/nearest-next/${voucherNumber}`
      );
      if (!response.data.success) {
        throw new Error('No next bill found');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  searchBills: async (params: {
    page?: number;
    limit?: number;
    startDate?: string;
    endDate?: string;
    customer?: string;
    minAmount?: number;
    maxAmount?: number;
  }): Promise<{
    bills: Bill[];
    pagination: {
      total: number;
      page: number;
      pages: number;
    }
  }> => {
    try {
      const queryString = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
          queryString.append(key, value.toString());
        }
      });

      const response = await api.get<ApiResponse<{
        bills: Bill[];
        pagination: {
          total: number;
          page: number;
          pages: number;
        }
      }>>(`/customerBills2/search?${queryString}`);
      
      if (!response.data.success) {
        throw new Error('Failed to search bills');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },

  fetchOutstandingBills: async (): Promise<{
    _id: string;
    customerName: string;
    totalBillOutstanding: number;
    bills: {
      _id: string;
      date: string;
      totalAmount: number;
      outstandingAmount: number;
    }[];
  }[]> => {
    try {
      const response = await api.get<ApiResponse<{
        _id: string;
        customerName: string;
        totalBillOutstanding: number;
        bills: {
          _id: string;
          date: string;
          totalAmount: number;
          outstandingAmount: number;
        }[];
      }[]>>('/customerBills2/search/outstanding');
      
      if (!response.data.success) {
        throw new Error('Failed to fetch outstanding bills');
      }
      return response.data.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }
};
