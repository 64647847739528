import React from 'react';
import { format } from 'date-fns';
import { MoneyReceived } from '../types/moneyTypes';
import { getMoneyReceived } from '../api/MoneyReceivedAPI';
import { toast } from 'react-toastify';

interface PreviousMoneyReceivedProps {
  isOpen: boolean;
  onClose: () => void;
}

const PreviousMoneyReceived: React.FC<PreviousMoneyReceivedProps> = ({
  isOpen,
  onClose,
}) => {
  const [entries, setEntries] = React.useState<MoneyReceived[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (isOpen) {
      const fetchEntries = async () => {
        try {
          setLoading(true);
          setError(null);
          const data = await getMoneyReceived();
          if (Array.isArray(data)) {
            setEntries(data);
          } else {
            console.error('Invalid money received data:', data);
            setEntries([]);
          }
        } catch (error) {
          console.error('Error fetching money received entries:', error);
          setError('Failed to fetch money received entries');
          toast.error('Failed to load previous money received entries');
        } finally {
          setLoading(false);
        }
      };

      fetchEntries();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[80vh] overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Previous Money Received</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : error ? (
          <div className="text-center text-red-500 py-4">{error}</div>
        ) : entries.length === 0 ? (
          <div className="text-center py-4">No money received entries found</div>
        ) : (
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border-b">Date</th>
                <th className="px-4 py-2 border-b">Voucher No.</th>
                <th className="px-4 py-2 border-b">Customer</th>
                <th className="px-4 py-2 border-b">Amount</th>
                <th className="px-4 py-2 border-b">Note</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry) => (
                <tr key={entry._id} className="hover:bg-gray-50">
                  <td className="px-4 py-2 border-b">
                    {format(new Date(entry.date), 'dd-MM-yyyy')}
                  </td>
                  <td className="px-4 py-2 border-b">{entry.voucherNumber}</td>
                  <td className="px-4 py-2 border-b">
                    {typeof entry.customer === 'object' 
                      ? entry.customer.name 
                      : 'Unknown Customer'}
                  </td>
                  <td className="px-4 py-2 border-b text-right">
                    {entry.amount.toLocaleString('en-IN', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="px-4 py-2 border-b">{entry.note || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PreviousMoneyReceived;
