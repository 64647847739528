import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { Bill, Customer, DEFAULT_BILL_ITEM, DEFAULT_OLD_ITEM } from '../types/bill';

export interface BillContextState {
  bill: Bill;
  isLoading: boolean;
  error: string | null;
  isDirty: boolean;
  customers: Customer[];
  filteredCustomers: Customer[];
  isCustomerSearchFocused: boolean;
  isPreviousBillsVisible: boolean;
  customersLoaded: boolean;
}

type BillAction = 
  | { type: 'SET_BILL'; payload: Bill }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'SET_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_FILTERED_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_CUSTOMER_SEARCH_FOCUS'; payload: boolean }
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'TOGGLE_TAX' }
  | { type: 'UPDATE_BILL_ITEMS'; payload: Bill['items'] }
  | { type: 'UPDATE_OLD_ITEMS'; payload: NonNullable<Bill['oldGoldSilverItems']> }
  | { type: 'SET_PREVIOUS_BILLS_VISIBLE'; payload: boolean }
  | { type: 'SET_CUSTOMERS_LOADED'; payload: boolean }
  | { type: 'UPDATE_TOTALS'; payload: Pick<Bill, 
      'itemsNetAmount' | 
      'taxAmount' | 
      'totalAmount' | 
      'oldGoldAmount' | 
      'oldSilverAmount' | 
      'osAmount'
    > };

const getInitialBillState = (voucherNumber = 1): Bill => ({
  customer: '',
  selectedCustomer: '',
  previousOutstanding: '0',
  date: new Date(),
  voucherNumber,
  items: [{ ...DEFAULT_BILL_ITEM }],
  oldGoldSilverItems: [{ ...DEFAULT_OLD_ITEM }],
  itemsNetAmount: 0,
  isTaxEnabled: true,
  taxAmount: 0,
  totalAmount: 0,
  oldGoldAmount: 0,
  oldSilverAmount: 0,
  schemeAmount: '0',
  receivedAmount: '0',
  givenAmount: '0',
  roundOffAmount: '0',
  osAmount: 0
});

const initialState: BillContextState = {
  bill: getInitialBillState(),
  isLoading: false,
  error: null,
  isDirty: false,
  customers: [],
  filteredCustomers: [],
  isCustomerSearchFocused: false,
  isPreviousBillsVisible: false,
  customersLoaded: false
};

const billReducer = (state: BillContextState, action: BillAction): BillContextState => {
  switch (action.type) {
    case 'SET_BILL': {
      const bill = action.payload;
      return { 
        ...state, 
        bill: {
          ...bill,
          items: bill.items?.map(item => ({ ...DEFAULT_BILL_ITEM, ...item })) || [{ ...DEFAULT_BILL_ITEM }],
          oldGoldSilverItems: bill.oldGoldSilverItems?.map(item => ({ ...DEFAULT_OLD_ITEM, ...item })) || [{ ...DEFAULT_OLD_ITEM }],
          schemeAmount: bill.schemeAmount || '0',
          receivedAmount: bill.receivedAmount || '0',
          givenAmount: bill.givenAmount || '0',
          roundOffAmount: bill.roundOffAmount || '0',
          previousOutstanding: bill.previousOutstanding || '0',
          voucherNumber: bill.voucherNumber || state.bill.voucherNumber
        },
        isDirty: true 
      };
    }
    
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    
    case 'SET_CUSTOMERS':
      return { 
        ...state, 
        customers: action.payload || [],
        customersLoaded: true
      };
    
    case 'SET_FILTERED_CUSTOMERS':
      return { ...state, filteredCustomers: action.payload || [] };
    
    case 'SET_CUSTOMER_SEARCH_FOCUS':
      return { ...state, isCustomerSearchFocused: action.payload };
    
    case 'SET_DIRTY':
      return { ...state, isDirty: action.payload };
    
    case 'TOGGLE_TAX':
      return {
        ...state,
        bill: {
          ...state.bill,
          isTaxEnabled: !state.bill.isTaxEnabled
        },
        isDirty: true
      };
    
    case 'UPDATE_BILL_ITEMS': {
      const items = action.payload;
      const updatedItems = items.map(item => ({
        ...DEFAULT_BILL_ITEM,
        ...state.bill.items.find(i => i === item) || {},
        ...item
      }));
      
      return {
        ...state,
        bill: {
          ...state.bill,
          items: updatedItems.length > 0 ? updatedItems : [{ ...DEFAULT_BILL_ITEM }]
        },
        isDirty: true
      };
    }
    
    case 'UPDATE_OLD_ITEMS': {
      const oldItems = action.payload;
      const updatedOldItems = oldItems.map(item => ({
        ...DEFAULT_OLD_ITEM,
        ...(state.bill.oldGoldSilverItems?.find(i => i === item) || {}),
        ...item
      }));
      
      return {
        ...state,
        bill: {
          ...state.bill,
          oldGoldSilverItems: updatedOldItems.length > 0 ? updatedOldItems : [{ ...DEFAULT_OLD_ITEM }]
        },
        isDirty: true
      };
    }

    case 'SET_PREVIOUS_BILLS_VISIBLE':
      return {
        ...state,
        isPreviousBillsVisible: action.payload
      };

    case 'SET_CUSTOMERS_LOADED':
      return {
        ...state,
        customersLoaded: action.payload
      };

    case 'UPDATE_TOTALS': {
      const totals = action.payload;
      return {
        ...state,
        bill: {
          ...state.bill,
          ...totals
        },
        isDirty: true
      };
    }
    
    default:
      return state;
  }
};

interface BillContextValue {
  state: BillContextState;
  dispatch: React.Dispatch<BillAction>;
}

const BillContext = createContext<BillContextValue | undefined>(undefined);

export const BillProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(billReducer, initialState);

  return (
    <BillContext.Provider value={{ state, dispatch }}>
      {children}
    </BillContext.Provider>
  );
};

export const useBillContext = () => {
  const context = useContext(BillContext);
  if (!context) {
    throw new Error('useBillContext must be used within a BillProvider');
  }
  return context;
};

export const billActions = {
  setBill: (bill: Bill) => ({ type: 'SET_BILL' as const, payload: bill }),
  setLoading: (isLoading: boolean) => ({ type: 'SET_LOADING' as const, payload: isLoading }),
  setError: (error: string | null) => ({ type: 'SET_ERROR' as const, payload: error }),
  setCustomers: (customers: Customer[]) => ({ type: 'SET_CUSTOMERS' as const, payload: customers }),
  setFilteredCustomers: (customers: Customer[]) => ({ type: 'SET_FILTERED_CUSTOMERS' as const, payload: customers }),
  setCustomerSearchFocus: (focused: boolean) => ({ type: 'SET_CUSTOMER_SEARCH_FOCUS' as const, payload: focused }),
  setDirty: (isDirty: boolean) => ({ type: 'SET_DIRTY' as const, payload: isDirty }),
  toggleTax: () => ({ type: 'TOGGLE_TAX' as const }),
  updateBillItems: (items: Bill['items']) => ({ type: 'UPDATE_BILL_ITEMS' as const, payload: items }),
  updateOldItems: (items: NonNullable<Bill['oldGoldSilverItems']>) => ({ type: 'UPDATE_OLD_ITEMS' as const, payload: items }),
  setPreviousBillsVisible: (visible: boolean) => ({ type: 'SET_PREVIOUS_BILLS_VISIBLE' as const, payload: visible }),
  setCustomersLoaded: (loaded: boolean) => ({ type: 'SET_CUSTOMERS_LOADED' as const, payload: loaded }),
  updateTotals: (totals: Pick<Bill, 
    'itemsNetAmount' | 
    'taxAmount' | 
    'totalAmount' | 
    'oldGoldAmount' | 
    'oldSilverAmount' | 
    'osAmount'
  >) => ({ type: 'UPDATE_TOTALS' as const, payload: totals })
};

export { getInitialBillState };
