import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Customer } from '../../types/bill';
import { useBillContext, billActions } from '../../contexts/BillContext';
import { customerApi } from '../../api/customerApi';

interface CustomerSearchProps {
  onCustomerSelect: (customer: Customer) => void;
  initialCustomerName?: string;
}

export const CustomerSearch: React.FC<CustomerSearchProps> = ({ 
  onCustomerSelect,
  initialCustomerName = ''
}) => {
  const { state, dispatch } = useBillContext();
  const [searchTerm, setSearchTerm] = useState(initialCustomerName);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const initialLoadDone = useRef(false);

  // Modified useEffect to prevent infinite loop
  useEffect(() => {
    const loadInitialCustomers = async () => {
      // Only load if we haven't loaded before and there are no customers
      if (initialLoadDone.current) {
        return;
      }

      try {
        setIsLocalLoading(true);
        setError(null);
        
        const customers = await customerApi.getCustomers();
        
        if (customers.length > 0) {
          dispatch(billActions.setCustomers(customers));
          dispatch(billActions.setFilteredCustomers(customers));
        } else {
          setError('No customers found');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load customers');
      } finally {
        initialLoadDone.current = true;
        setIsLocalLoading(false);
      }
    };

    loadInitialCustomers();
  }, [dispatch]); // Only depend on dispatch

  const filterCustomers = useCallback((term: string) => {
    if (!state.customers?.length) return;

    if (!term) {
      dispatch(billActions.setFilteredCustomers(state.customers));
      return;
    }

    const searchTermLower = term.toLowerCase();
    const filtered = state.customers.filter(customer =>
      customer.name.toLowerCase().includes(searchTermLower) ||
      (customer.phone && customer.phone.includes(term)) ||
      (customer.address && customer.address.toLowerCase().includes(searchTermLower)) ||
      (customer.city && customer.city.toLowerCase().includes(searchTermLower))
    );

    dispatch(billActions.setFilteredCustomers(filtered));
  }, [state.customers, dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isDropdownOpen) {
        filterCustomers(searchTerm);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm, isDropdownOpen, filterCustomers]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (initialCustomerName !== searchTerm) {
      setSearchTerm(initialCustomerName);
    }
  }, [initialCustomerName]);

  const handleCustomerSelect = (customer: Customer) => {
    onCustomerSelect(customer);
    setSearchTerm(customer.name);
    setIsDropdownOpen(false);
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
    setError(null);
    if (state.customers?.length > 0) {
      dispatch(billActions.setFilteredCustomers(state.customers));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    setIsDropdownOpen(true);
    setError(null);
  };

  const isLoading = isLocalLoading || state.isLoading;
  const showDropdown = isDropdownOpen && !isLoading;
  const filteredCustomers = state.filteredCustomers || [];

  return (
    <div className="relative" ref={searchRef}>
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={isLoading ? 'Loading customers...' : 'Click to show all customers or type to search'}
          disabled={isLoading}
        />
        {isLoading && (
          <div className="absolute right-3 top-2">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
          </div>
        )}
      </div>

      {error && (
        <div className="text-red-500 mt-1">{error}</div>
      )}

      {showDropdown && filteredCustomers.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-2 py-1 text-left font-medium text-gray-500 uppercase">Name</th>
                <th className="px-2 py-1 text-left font-medium text-gray-500 uppercase">Phone</th>
                <th className="px-2 py-1 text-left font-medium text-gray-500 uppercase">Address</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredCustomers.map((customer) => (
                <tr
                  key={customer._id}
                  onClick={() => handleCustomerSelect(customer)}
                  className="hover:bg-gray-100 cursor-pointer"
                >
                  <td className="px-2 py-1">{customer.name}</td>
                  <td className="px-2 py-1">{customer.phone || 'N/A'}</td>
                  <td className="px-2 py-1">{customer.address || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showDropdown && searchTerm && filteredCustomers.length === 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md p-4 text-center text-gray-500">
          No customers found
        </div>
      )}
    </div>
  );
};
