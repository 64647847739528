import React from 'react';
import { VendorBillForm } from '../features/vendorBill/components';

const VendorBill: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <VendorBillForm />
    </div>
  );
};

export default VendorBill;
