import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  GoldFineCutOldTable,
  GoldFineCutCashTable,
  SilverFineCutOldTable,
  SilverFineCutCashTable,
  FineCutTotalsTable,
  VendorFineCutFormHeader
} from './';
import { 
  GoldFineCutOldItem, 
  GoldFineCutCashItem,
  SilverFineCutOldItem,
  SilverFineCutCashItem,
  createVendorFineCut,
  getVendorFineCuts,
  fetchVendorFineCutDetails,
  fetchNearestPreviousVendorFineCut,
  fetchNearestNextVendorFineCut,
  updateVendorFineCut
} from './VendorFineCutAPI';
import { AuthContext } from '../../contexts/AuthContext';

interface Vendor {
  _id: string;
  name: string;
}

interface VendorFineCutFormProps {
  fineCutId?: string;
}

const emptyGoldFineCutOldItem: GoldFineCutOldItem = {
  wt: '',
  tunch: '',
  pctg: '',
  fine: ''
};

const emptyGoldFineCutCashItem: GoldFineCutCashItem = {
  amt: '',
  rate: '',
  pctg: '',
  fine: ''
};

const emptySilverFineCutOldItem: SilverFineCutOldItem = {
  wt: '',
  tunch: '',
  fine: ''
};

const emptySilverFineCutCashItem: SilverFineCutCashItem = {
  amt: '',
  rate: '',
  fine: ''
};

const VendorFineCutForm: React.FC<VendorFineCutFormProps> = ({ fineCutId }) => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('AuthContext not found');
  }

  // Form state
  const [date, setDate] = useState<Date>(new Date());
  const [voucherNumber, setVoucherNumber] = useState<number>(0);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [goldFineCutOld, setGoldFineCutOld] = useState<GoldFineCutOldItem[]>([{ ...emptyGoldFineCutOldItem }]);
  const [goldFineCutCash, setGoldFineCutCash] = useState<GoldFineCutCashItem[]>([{ ...emptyGoldFineCutCashItem }]);
  const [silverFineCutOld, setSilverFineCutOld] = useState<SilverFineCutOldItem[]>([{ ...emptySilverFineCutOldItem }]);
  const [silverFineCutCash, setSilverFineCutCash] = useState<SilverFineCutCashItem[]>([{ ...emptySilverFineCutCashItem }]);
  const [goldFineCut, setGoldFineCut] = useState('0');
  const [silverFineCut, setSilverFineCut] = useState('0');

  // UI state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [editingFineCutId, setEditingFineCutId] = useState<string | null>(null);
  const voucherNumberRef = useRef<HTMLInputElement>(null);

  // Load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!auth.loading && auth.user) {
        try {
          const fineCutsData = await getVendorFineCuts();
          
          // Set the next voucher number if not editing
          if (!fineCutId && !editingFineCutId) {
            setVoucherNumber(fineCutsData.nextVoucherNumber);
          }
        } catch (error) {
          console.error('Error loading initial data:', error);
          setError('Failed to load initial data');
        }
      }
    };
    
    loadInitialData();
  }, [fineCutId, editingFineCutId, auth.loading, auth.user]);

  // Load fine cut data if editing
  useEffect(() => {
    if (fineCutId && auth.user) {
      handleEdit(fineCutId);
    }
  }, [fineCutId, auth.user]);

  // Calculate Gold Fine Cut Total
  useEffect(() => {
    const oldGoldFine = goldFineCutOld.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    const cashGoldFine = goldFineCutCash.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    setGoldFineCut((oldGoldFine + cashGoldFine).toFixed(3));
  }, [goldFineCutOld, goldFineCutCash]);

  // Calculate Silver Fine Cut Total
  useEffect(() => {
    const oldSilverFine = silverFineCutOld.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    const cashSilverFine = silverFineCutCash.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    setSilverFineCut((oldSilverFine + cashSilverFine).toFixed(3));
  }, [silverFineCutOld, silverFineCutCash]);

  const handleEdit = async (editFineCutId: string) => {
    try {
      setEditingFineCutId(editFineCutId);
      const fineCutData = await fetchVendorFineCutDetails(editFineCutId);
      
      setDate(new Date(fineCutData.date));
      setVoucherNumber(fineCutData.voucherNumber);
      setSelectedVendor({
        _id: fineCutData.vendor,
        name: fineCutData.selectedVendor
      });
      setGoldFineCutOld(fineCutData.goldFineCutOld);
      setGoldFineCutCash(fineCutData.goldFineCutCash);
      setSilverFineCutOld(fineCutData.silverFineCutOld);
      setSilverFineCutCash(fineCutData.silverFineCutCash);
    } catch (error) {
      console.error('Error loading fine cut for edit:', error);
      setError('Failed to load fine cut for editing');
    }
  };

  const handlePreviousFineCut = async () => {
    if (voucherNumber > 1) {
      try {
        const previousFineCut = await fetchNearestPreviousVendorFineCut(voucherNumber);
        if (previousFineCut && previousFineCut._id) {
          handleEdit(previousFineCut._id);
        }
      } catch (err) {
        console.error('Error fetching previous fine cut:', err);
        setError('Failed to fetch previous fine cut');
      }
    }
  };

  const handleNextFineCut = async () => {
    try {
      const nextFineCut = await fetchNearestNextVendorFineCut(voucherNumber);
      if (nextFineCut && nextFineCut._id) {
        handleEdit(nextFineCut._id);
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error('Error fetching next fine cut:', err);
      window.location.reload();
    }
  };

  const validateForm = (): string | null => {
    if (!selectedVendor) {
      return 'Please select a vendor';
    }

    // Check if there's at least one valid item in any table
    const hasValidGoldFineCutOld = goldFineCutOld.some(item => {
      const wt = parseFloat(item.wt) || 0;
      const tunch = parseFloat(item.tunch) || 0;
      const pctg = parseFloat(item.pctg) || 0;
      return wt > 0 && (tunch > 0 || pctg > 0);
    });

    const hasValidGoldFineCutCash = goldFineCutCash.some(item => {
      const amt = parseFloat(item.amt) || 0;
      const rate = parseFloat(item.rate) || 0;
      const pctg = parseFloat(item.pctg) || 0;
      return amt > 0 && rate > 0 && pctg > 0;
    });

    const hasValidSilverFineCutOld = silverFineCutOld.some(item => {
      const wt = parseFloat(item.wt) || 0;
      const tunch = parseFloat(item.tunch) || 0;
      return wt > 0 && tunch > 0;
    });

    const hasValidSilverFineCutCash = silverFineCutCash.some(item => {
      const amt = parseFloat(item.amt) || 0;
      const rate = parseFloat(item.rate) || 0;
      return amt > 0 && rate > 0;
    });

    // Allow saving if any table has valid values
    if (!hasValidGoldFineCutOld && !hasValidGoldFineCutCash && 
        !hasValidSilverFineCutOld && !hasValidSilverFineCutCash) {
      return 'Please add at least one valid item in any table';
    }

    return null;
  };

  const resetForm = () => {
    setDate(new Date());
    setSelectedVendor(null);
    setGoldFineCutOld([{ ...emptyGoldFineCutOldItem }]);
    setGoldFineCutCash([{ ...emptyGoldFineCutCashItem }]);
    setSilverFineCutOld([{ ...emptySilverFineCutOldItem }]);
    setSilverFineCutCash([{ ...emptySilverFineCutCashItem }]);
    setGoldFineCut('0');
    setSilverFineCut('0');
    setError(null);
    setSuccessMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const vendorFineCutData = {
        vendor: selectedVendor!._id,
        selectedVendor: selectedVendor!.name,
        date,
        voucherNumber,
        goldFineCutOld,
        goldFineCutCash,
        silverFineCutOld,
        silverFineCutCash,
        goldFineCut,
        silverFineCut
      };

      if (editingFineCutId) {
        await updateVendorFineCut(editingFineCutId, vendorFineCutData);
        setSuccessMessage('Vendor fine cut updated successfully');
      } else {
        await createVendorFineCut(vendorFineCutData);
        setSuccessMessage('Vendor fine cut saved successfully');
      }

      resetForm();
      const fineCutsData = await getVendorFineCuts();
      setVoucherNumber(fineCutsData.nextVoucherNumber);
    } catch (error) {
      console.error('Error saving vendor fine cut:', error);
      setError('Failed to save vendor fine cut');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Show loading state while auth is initializing
  if (auth.loading) {
    return <div>Loading...</div>;
  }

  // Show message if not authenticated
  if (!auth.user) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* Error and Success Messages */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          {successMessage}
        </div>
      )}

      {/* Header Section */}
      <VendorFineCutFormHeader
        selectedVendor={selectedVendor}
        onVendorSelect={setSelectedVendor}
        date={date}
        setDate={setDate}
        voucherNumber={voucherNumber}
      />
      
      {/* Gold Sections */}
      <div className="mb-8">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <h2 className="text-base font-bold mb-2">Gold Fine Cut (Old Gold)</h2>
            <GoldFineCutOldTable 
              tableItems={goldFineCutOld}
              setTableItems={setGoldFineCutOld}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h2 className="text-base font-bold mb-2">Gold Fine Cut (Cash)</h2>
            <GoldFineCutCashTable 
              tableItems={goldFineCutCash}
              setTableItems={setGoldFineCutCash}
            />
          </div>
        </div>
      </div>

      {/* Silver Sections */}
      <div className="mb-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <h2 className="text-base font-bold mb-2">Silver Fine Cut (Old Silver)</h2>
            <SilverFineCutOldTable 
              tableItems={silverFineCutOld}
              setTableItems={setSilverFineCutOld}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <h2 className="text-base font-bold mb-2">Silver Fine Cut (Cash)</h2>
            <SilverFineCutCashTable 
              tableItems={silverFineCutCash}
              setTableItems={setSilverFineCutCash}
            />
          </div>
        </div>
      </div>

      {/* Totals Section */}
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full px-3">
          <FineCutTotalsTable
            goldFineCut={goldFineCut}
            silverFineCut={silverFineCut}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex items-center justify-between mt-6">
        <div className="flex items-center space-x-4">
          {voucherNumber > 1 && (
            <button
              type="button"
              onClick={handlePreviousFineCut}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              title="Previous Fine Cut"
            >
              &lt;
            </button>
          )}
          <button
            type="button"
            onClick={handleNextFineCut}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            title={voucherNumber ? "Next Fine Cut" : "Create New Fine Cut"}
          >
            &gt;
          </button>
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`${
            isSubmitting ? 'bg-blue-300' : 'bg-blue-500 hover:bg-blue-700'
          } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
        >
          {isSubmitting ? 'Saving...' : (editingFineCutId ? 'Update Fine Cut' : 'Save Fine Cut')}
        </button>
      </div>
    </form>
  );
};

export default VendorFineCutForm;
