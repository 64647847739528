import React, { FormEvent, ChangeEvent } from 'react';

export interface Vendor {
  _id: string;
  userId: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  note: string;
  date: Date;
}

interface VendorFormProps {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  note: string;
  editingVendor: any;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onAddressChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onCityChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onStateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPinCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onNoteChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onReset: () => void;
}

export const VendorForm: React.FC<VendorFormProps> = ({
  name,
  phone,
  address,
  city,
  state,
  pinCode,
  note,
  editingVendor,
  onSubmit,
  onNameChange,
  onPhoneChange,
  onAddressChange,
  onCityChange,
  onStateChange,
  onPinCodeChange,
  onNoteChange,
  onReset,
}) => {
  return (
      <form onSubmit={onSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={onNameChange}
            required
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="tel"
            placeholder="Phone"
            value={phone}
            onChange={onPhoneChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
            Address
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="address"
            placeholder="Address"
            value={address}
            onChange={onAddressChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
            City
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder="City"
            value={city}
            onChange={onCityChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="state">
            State
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="state"
            type="text"
            placeholder="State"
            value={state}
            onChange={onStateChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pinCode">
            Pin Code
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pinCode"
            type="text"
            placeholder="Pin Code"
            value={pinCode}
            onChange={onPinCodeChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="note">
            Note
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="note"
            placeholder="Add a note about this vendor"
            value={note}
            onChange={onNoteChange}
            rows={2}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {editingVendor ? 'Update Vendor' : 'Add Vendor'}
          </button>
          {editingVendor && (
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onReset}
            >
              Cancel
            </button>
          )}
        </div>
      </form>
  );
};

export default VendorForm;
