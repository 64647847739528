import React, { useState, useEffect, useContext, ChangeEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerList from '../features/customers/CustomerList';
import CustomerForm from '../features/customers/CustomerForm';
import { fetchCustomers, createCustomer, updateCustomer, deleteCustomer } from '../features/customers/api';
import { AuthContext } from '../contexts/AuthContext';

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [pinCode, setPinCode] = useState<string>('');
  const [creditLimit, setCreditLimit] = useState<string>('');
  const [referredBy, setReferredBy] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [editingCustomer, setEditingCustomer] = useState<any | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const authContext = useContext(AuthContext);
  const user = authContext?.user;
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchCustomersData();
    }
  }, [user, navigate]);

  const fetchCustomersData = async () => {
    try {
      setLoading(true);
      const data = await fetchCustomers();
      setCustomers(data);
      setError('');
    } catch (err: any) {
      console.error('Error fetching customers:', err);
      if (err.response && err.response.status === 401) {
        setError('Authentication error. Please log in again.');
        navigate('/login');
      } else {
        setError('Failed to fetch customers. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      const customerData = {
        name,
        phone,
        address,
        city,
        state,
        pinCode,
        creditLimit: parseFloat(creditLimit),
        referredBy,
        note
      };
      if (editingCustomer) {
        await updateCustomer(editingCustomer._id, customerData);
      } else {
        await createCustomer(customerData);
      }
      resetForm();
      fetchCustomersData();
    } catch (err: any) {
      console.error('Error saving customer:', err);
      setError('Failed to save customer. Please try again.');
    }
  };

  const handleEdit = (customer: any) => {
    setEditingCustomer(customer);
    setName(customer.name);
    setPhone(customer.phone);
    setAddress(customer.address);
    setCity(customer.city || '');
    setState(customer.state || '');
    setPinCode(customer.pinCode || '');
    setCreditLimit(customer.creditLimit?.toString() || '');
    setReferredBy(customer.referredBy || '');
    setNote(customer.note || '');
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await deleteCustomer(id);
        fetchCustomersData();
      } catch (err: any) {
        console.error('Error deleting customer:', err);
        setError('Failed to delete customer. Please try again.');
      }
    }
  };

  const resetForm = () => {
    setName('');
    setPhone('');
    setAddress('');
    setCity('');
    setState('');
    setPinCode('');
    setCreditLimit('');
    setReferredBy('');
    setNote('');
    setEditingCustomer(null);
  };

  const capitalizeWords = (string: string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const capitalizedName = capitalizeWords(e.target.value);
    setName(capitalizedName);
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 ">
      <div className="mx-auto py-6 sm:px-6 lg:px-8 ">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <div className="flex flex-col md:flex-row ">

          {/* Customer List Section */}
          <div className="w-full md:w-3/5 pr-0 md:pr-4 mb-6 md:mb-0">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Customer List</h2>
            <CustomerList 
              customers={customers}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </div>

          {/* New Customer Section */}
          <div className="w-full md:w-2/5 pl-0 md:pl-4">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {editingCustomer ? 'Edit Customer' : 'New Customer'}
            </h2>
            <CustomerForm
              name={name}
              phone={phone}
              address={address}
              city={city}
              state={state}
              pinCode={pinCode}
              creditLimit={creditLimit}
              referredBy={referredBy}
              note={note}
              editingCustomer={editingCustomer}
              onSubmit={handleSubmit}
              onNameChange={handleNameChange}
              onPhoneChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
              onAddressChange={(e: ChangeEvent<HTMLTextAreaElement>) => setAddress(e.target.value)}
              onCityChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
              onStateChange={(e: React.ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
              onPinCodeChange={(e: React.ChangeEvent<HTMLInputElement>) => setPinCode(e.target.value)}
              onCreditLimitChange={(e: React.ChangeEvent<HTMLInputElement>) => setCreditLimit(e.target.value)}
              onReferredByChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferredBy(e.target.value)}
              onNoteChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value)}
              onReset={resetForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
