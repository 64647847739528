import React from 'react';
import { BillForm } from '../features/customerBill2/components/BillForm';
import { BillProvider } from '../features/customerBill2/contexts/BillContext';

const CustomerBill: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <BillProvider>
        <BillForm />
      </BillProvider>
    </div>
  );
};

export default CustomerBill;
