import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

export const fetchVendors = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/vendors`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createVendor = async (vendorData: any) => {
  try {
    const response = await axios.post(`${API_URL}/api/vendors`, vendorData, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateVendor = async (id: string, vendorData: any) => {
  try {
    const response = await axios.put(`${API_URL}/api/vendors/${id}`, vendorData, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVendor = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/api/vendors/${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
