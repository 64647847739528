import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

// Create axios instance with default config
const api = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Add request interceptor to include auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Gold Fine Cut Interfaces
export interface GoldFineCutOldItem {
  wt: string;
  tunch: string;
  pctg: string;
  fine: string;
}

export interface GoldFineCutCashItem {
  amt: string;
  rate: string;
  pctg: string;
  fine: string;
}

// Silver Fine Cut Interfaces
export interface SilverFineCutOldItem {
  wt: string;
  tunch: string;
  fine: string;
}

export interface SilverFineCutCashItem {
  amt: string;
  rate: string;
  fine: string;
}

export interface VendorFineCut {
  _id?: string;
  voucherNumber: number;  // Changed from string to number
  vendor: string;
  selectedVendor: string;
  date: Date;
  goldFineCutOld: GoldFineCutOldItem[];
  goldFineCutCash: GoldFineCutCashItem[];
  silverFineCutOld: SilverFineCutOldItem[];
  silverFineCutCash: SilverFineCutCashItem[];
  goldFineCut: string;
  silverFineCut: string;
}

interface VendorFineCutsResponse {
  vendorFineCuts: VendorFineCut[];
  nextVoucherNumber: number;
}

// Helper function to validate gold fine cut old item
const isValidGoldFineCutOldItem = (item: GoldFineCutOldItem): boolean => {
  return !!(
    item.wt &&
    item.tunch &&
    item.pctg &&
    item.fine
  );
};

// Helper function to validate gold fine cut cash item
const isValidGoldFineCutCashItem = (item: GoldFineCutCashItem): boolean => {
  return !!(
    item.amt &&
    item.rate &&
    item.pctg &&
    item.fine
  );
};

export const createVendorFineCut = async (vendorFineCutData: Partial<VendorFineCut>) => {
  try {
    // Filter out invalid items
    const validGoldFineCutOld = vendorFineCutData.goldFineCutOld?.filter(isValidGoldFineCutOldItem) || [];
    const validGoldFineCutCash = vendorFineCutData.goldFineCutCash?.filter(isValidGoldFineCutCashItem) || [];
    
    // Only send valid items
    const dataToSend = {
      ...vendorFineCutData,
      goldFineCutOld: validGoldFineCutOld,
      goldFineCutCash: validGoldFineCutCash
    };

    const response = await api.post('/vendor-fine-cuts', dataToSend);
    return response.data;
  } catch (error) {
    console.error('Error creating vendor fine cut:', error);
    throw error;
  }
};

export const getVendorFineCuts = async (): Promise<VendorFineCutsResponse> => {
  try {
    const response = await api.get('/vendor-fine-cuts');
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor fine cuts:', error);
    throw error;
  }
};

export const getVendorFineCut = async (id: string) => {
  try {
    const response = await api.get(`/vendor-fine-cuts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor fine cut:', error);
    throw error;
  }
};

export const updateVendorFineCut = async (id: string, vendorFineCutData: Partial<VendorFineCut>) => {
  try {
    // Filter out invalid items
    const validGoldFineCutOld = vendorFineCutData.goldFineCutOld?.filter(isValidGoldFineCutOldItem) || [];
    const validGoldFineCutCash = vendorFineCutData.goldFineCutCash?.filter(isValidGoldFineCutCashItem) || [];
    
    // Only send valid items
    const dataToSend = {
      ...vendorFineCutData,
      goldFineCutOld: validGoldFineCutOld,
      goldFineCutCash: validGoldFineCutCash
    };

    const response = await api.put(`/vendor-fine-cuts/${id}`, dataToSend);
    return response.data;
  } catch (error) {
    console.error('Error updating vendor fine cut:', error);
    throw error;
  }
};

export const deleteVendorFineCut = async (id: string) => {
  try {
    const response = await api.delete(`/vendor-fine-cuts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting vendor fine cut:', error);
    throw error;
  }
};

export const fetchVendorFineCutDetails = async (fineCutId: string) => {
  try {
    const response = await api.get(`/vendor-fine-cuts/${fineCutId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor fine cut:', error);
    throw error;
  }
};

export const fetchNearestPreviousVendorFineCut = async (voucherNumber: number) => {
  try {
    const response = await api.get(`/vendor-fine-cuts/previous/${voucherNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching previous vendor fine cut:', error);
    throw error;
  }
};

export const fetchNearestNextVendorFineCut = async (voucherNumber: number) => {
  try {
    const response = await api.get(`/vendor-fine-cuts/next/${voucherNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching next vendor fine cut:', error);
    throw error;
  }
};
