import React, { useRef, useEffect, useState } from 'react';
import { fetchVendors } from '../../features/vendors/api';

interface Vendor {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
}

interface VendorSearchProps {
  selectedVendor: Vendor | null;
  onVendorSelect: (vendor: Vendor | null) => void;
}

const VendorSearch: React.FC<VendorSearchProps> = ({ selectedVendor, onVendorSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<Vendor[]>([]);
  const vendorSearchRef = useRef<HTMLDivElement>(null);

  // Fetch vendors on component mount
  useEffect(() => {
    const getVendors = async () => {
      try {
        const data = await fetchVendors();
        setVendors(data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };
    getVendors();
  }, []);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (vendorSearchRef.current && !vendorSearchRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle vendor search input
  const handleVendorSearch = (value: string) => {
    setSearchTerm(value);
    
    if (!value) {
      setIsDropdownOpen(true);
      return;
    }

    setIsDropdownOpen(true);

    if (vendors?.length) {
      const filtered = vendors.filter(vendor =>
        vendor.name.toLowerCase().includes(value.toLowerCase()) ||
        (vendor.phone && vendor.phone.includes(value)) ||
        (vendor.address && vendor.address.toLowerCase().includes(value.toLowerCase())) ||
        (vendor.city && vendor.city.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredVendors(filtered);
    } else {
      setFilteredVendors([]);
    }
  };

  // Handle vendor selection
  const handleVendorSelect = (vendor: Vendor) => {
    setSearchTerm(vendor.name);
    onVendorSelect(vendor);
    setIsDropdownOpen(false);
  };

  return (
    <div className="mb-4 relative" ref={vendorSearchRef}>
      <label className="block text-gray-700 font-bold mb-2" htmlFor="vendorName">
        Vendor Name
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="vendorName"
        type="text"
        placeholder="Search vendor..."
        value={selectedVendor ? selectedVendor.name : searchTerm}
        onChange={(e) => {
          handleVendorSearch(e.target.value);
          if (selectedVendor) onVendorSelect(null);
        }}
        onFocus={() => {
          setIsDropdownOpen(true);
          if (searchTerm && filteredVendors.length === 0) {
            handleVendorSearch(searchTerm);
          }
        }}
      />

      {isDropdownOpen && filteredVendors.length > 0 && (
        <div className="absolute z-10 bg-white border border-gray-300 mt-1 w-full shadow-lg rounded-md overflow-hidden max-h-60 overflow-y-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Phone</th>
                <th className="px-4 py-2 text-left">Address</th>
                <th className="px-4 py-2 text-left">City</th>
              </tr>
            </thead>
            <tbody>
              {filteredVendors.map((vendor) => (
                <tr
                  key={vendor._id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleVendorSelect(vendor)}
                >
                  <td className="px-4 py-2">{vendor.name}</td>
                  <td className="px-4 py-2">{vendor.phone || 'N/A'}</td>
                  <td className="px-4 py-2">{vendor.address || 'N/A'}</td>
                  <td className="px-4 py-2">{vendor.city || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VendorSearch;
