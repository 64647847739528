import React from 'react';
import MoneyReceivedForm from '../features/moneyReceived/components/MoneyReceivedForm';

const MoneyReceived: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <MoneyReceivedForm />
    </div>
  );
};

export default MoneyReceived;
