import { BillItem, OldGoldSilverItem, Bill } from '../types/billingTypes';

export const calculateTotals = (
  billItems: BillItem[],
  oldGoldSilverItems: OldGoldSilverItem[],
  isTaxEnabled: boolean,
  schemeAmount: string,
  receivedAmount: string,
  givenAmount: string,
  roundOffAmount: string
): Pick<Bill, 'itemsNetAmount' | 'taxAmount' | 'totalAmount' | 'oldGoldAmount' | 'oldSilverAmount' | 'schemeAmount' | 'receivedAmount' | 'givenAmount' | 'roundOffAmount' | 'osAmount'> => {
  const itemsNetAmount = billItems.reduce((total, item) => total + (item.netAmt || 0), 0);

  const oldGoldAmount = oldGoldSilverItems
    .filter(item => item.type === 'Old Gold')
    .reduce((total, item) => total + (item.amt || 0), 0);

  const oldSilverAmount = oldGoldSilverItems
    .filter(item => item.type === 'Old Silver')
    .reduce((total, item) => total + (item.amt || 0), 0);

  let taxAmount = 0;
  if (isTaxEnabled) {
    taxAmount = itemsNetAmount * 0.03; // 3% tax rate
  }

  const totalAmount = itemsNetAmount + taxAmount;

  const osAmount = totalAmount - oldGoldAmount - oldSilverAmount - parNum(schemeAmount) - parNum(receivedAmount) + parNum(givenAmount) - parNum(roundOffAmount);

  return {
    itemsNetAmount,
    taxAmount,
    totalAmount,
    oldGoldAmount,
    oldSilverAmount,
    schemeAmount,
    receivedAmount,
    givenAmount,
    roundOffAmount,
    osAmount
  };
};

export const initializeNewBill = (voucherNumber: number = 1): Bill => {
  const now = new Date();

  return {
    customer: '',
    date: now,
    voucherNumber,
    selectedCustomer: "",
    previousOutstanding: '0',
    items: [{ 
      tagNo: '',
      itemId: '',
      itemName: '',
      pcs: 0,
      grossWt: '',
      lessWt: '',
      netWt: 0,
      rate: '',
      amt: 0,
      lbrPercent: '',
      lbrAmt: 0,
      otherAmt: '',
      totAmt: 0,
      taxPercent: '',
      netAmt: 0,
    }],
    oldGoldSilverItems: [{ 
      type: 'Old Gold',
      itemId: '',
      itemName: '',
      pcs: 0,
      weight: '',
      rate: '',
      amt: 0
    }],
    itemsNetAmount: 0,
    isTaxEnabled: true,
    totalAmount: 0,
    oldGoldAmount: 0,
    oldSilverAmount: 0,
    schemeAmount: '',
    receivedAmount: '',
    givenAmount: '',
    roundOffAmount: '',
    osAmount: 0
  };
};

export const formatDisplayValue = (value: number | string): string => {
  if (typeof value === 'string') {
    return value === '0' ? '' : value;
  } else {
    return value === 0 ? '' : value.toString();
  }
};

export const parNum = (value: string | number): number => {
  const parsed = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(parsed) ? 0 : parsed;
};

export const parInt = (value: string | number): number => {
  const parsed = typeof value === 'string' ? parseInt(value, 10) : value;
  return isNaN(parsed) ? 0 : parsed;
};

export const formetNumThree = (value: number): string => {
  return value === 0 ? '' : value.toFixed(3);
};

export const formetNumZero = (value: number): string => {
  return value === 0 ? '' : value.toFixed(0);
};

// This regex allows for the entry of integers, decimals (only one decimal dot is allowed), and even an empty string
export const decimalNumberRegex = /^\d*\.?\d*$/;

// Common utility functions for handling input changes

// Generic type for items that can be either BillItem or OldGoldSilverItem
type ItemType = BillItem | OldGoldSilverItem;

// Function to handle pieces (pcs) input changes
export const handlePcsChange = <T extends ItemType>(
  value: string,
  item: T,
  calculateValues: (item: T) => T
): T => {
  const pcsValue = Math.max(0, parseInt(value, 10));
  return calculateValues({ ...item, pcs: isNaN(pcsValue) ? 0 : pcsValue });
};

// Function to handle decimal number input changes
export const handleDecimalChange = <T extends ItemType>(
  value: string,
  field: keyof T,
  item: T,
  calculateValues: (item: T) => T
): T | null => {
  if (decimalNumberRegex.test(value)) {
    return calculateValues({ ...item, [field]: value });
  }
  return null;
};

// Function to handle text input changes
export const handleTextChange = <T extends ItemType>(
  value: string,
  field: keyof T,
  item: T,
  calculateValues: (item: T) => T
): T => {
  return calculateValues({ ...item, [field]: value });
};

// Generic function to handle all input changes
export const handleInputChange = <T extends ItemType>(
  field: keyof T,
  value: string,
  item: T,
  calculateValues: (item: T) => T
): T | null => {
  if (field === 'pcs') {
    return handlePcsChange(value, item, calculateValues);
  } else if (['grossWt', 'lessWt', 'rate', 'weight', 'lbrPercent', 'otherAmt', 'taxPercent'].includes(field as string)) {
    return handleDecimalChange(value, field, item, calculateValues);
  } else {
    return handleTextChange(value, field, item, calculateValues);
  }
};
