import React from 'react';
import { Dialog } from '@headlessui/react';

interface Bill {
  _id: string;
  date: string;
  totalAmount: number;
  outstandingAmount: number;
}

interface MoneyReceivedEntry {
  _id: string;
  date: string;
  amount: number;
}

interface CustomerStatementPopupProps {
  isOpen: boolean;
  onClose: () => void;
  customerName: string;
  bills: Bill[];
  moneyReceived: MoneyReceivedEntry[];
  totalBillOutstanding: number;
  totalMoneyReceived: number;
  totalOutstanding: number;
}

interface StatementEntry {
  _id: string;
  date: string;
  description: string;
  debit?: number;
  credit?: number;
  balance: number;
}

const CustomerStatementPopup: React.FC<CustomerStatementPopupProps> = ({
  isOpen,
  onClose,
  customerName,
  bills,
  moneyReceived,
  totalBillOutstanding,
  totalMoneyReceived,
  totalOutstanding,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Combine and sort bills and entries
  const statementEntries: StatementEntry[] = [
    ...bills.map((bill) => ({
      _id: bill._id,
      date: bill.date,
      description: 'Bill Generated',
      debit: bill.outstandingAmount,
      balance: 0, // Will be calculated below
    })),
    ...moneyReceived.map((entry) => ({
      _id: entry._id,
      date: entry.date,
      description: 'Money Received',
      credit: entry.amount,
      balance: 0, // Will be calculated below
    })),
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Calculate running balance
  let runningBalance = 0;
  statementEntries.forEach((entry) => {
    if (entry.debit) {
      runningBalance += entry.debit;
    }
    if (entry.credit) {
      runningBalance -= entry.credit;
    }
    entry.balance = runningBalance;
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <Dialog.Panel className="relative bg-white rounded-lg max-w-4xl w-full p-6 shadow-xl">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-bold text-gray-900">
              Statement of Account - {customerName}
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Debit (₹)
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Credit (₹)
                  </th>
                  <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Balance (₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {statementEntries.map((entry) => (
                  <tr key={entry._id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {formatDate(entry.date)}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {entry.description}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                      {entry.debit ? entry.debit.toFixed(2) : '-'}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-green-600 text-right">
                      {entry.credit ? entry.credit.toFixed(2) : '-'}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-right">
                      {entry.balance.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 border-t pt-4 space-y-2">
            <div className="flex justify-between text-sm">
              <span className="font-medium">Total Bills:</span>
              <span className="text-gray-900">₹{totalBillOutstanding.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="font-medium">Total Money Received:</span>
              <span className="text-green-600">₹{totalMoneyReceived.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm font-bold border-t pt-2">
              <span>Current Outstanding:</span>
              <span className="text-red-600">₹{totalOutstanding.toFixed(2)}</span>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CustomerStatementPopup;
