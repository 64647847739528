import axios from 'axios';
import { Customer } from './CustomerForm';

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add auth token to all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Handle 401 responses globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login or handle auth error
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Omit server-generated fields from input type
type CustomerInput = Omit<Customer, '_id' | 'userId' | 'date'>;

export const fetchCustomers = async (): Promise<Customer[]> => {
  try {
    const res = await api.get<Customer[]>('/customers');
    return res.data;
  } catch (err) {
    console.error('Error fetching customers:', err);
    throw err;
  }
};

export const createCustomer = async (customerData: CustomerInput): Promise<Customer> => {
  try {
    const res = await api.post<Customer>('/customers', customerData);
    return res.data;
  } catch (err: any) {
    if (err.response?.status === 400 && err.response.data.msg === 'Name is required') {
      throw new Error('Name is required to create a customer');
    }
    if (err.response?.status === 401) {
      throw new Error('Authentication required');
    }
    console.error('Error creating customer:', err);
    throw err;
  }
};

export const updateCustomer = async (id: string, customerData: CustomerInput): Promise<Customer> => {
  try {
    const res = await api.put<Customer>(`/customers/${id}`, customerData);
    return res.data;
  } catch (err: any) {
    if (err.response?.status === 404) {
      throw new Error('Customer not found');
    }
    if (err.response?.status === 401) {
      throw new Error('Authentication required');
    }
    console.error('Error updating customer:', err);
    throw err;
  }
};

export const deleteCustomer = async (id: string): Promise<void> => {
  try {
    await api.delete(`/customers/${id}`);
  } catch (err: any) {
    if (err.response?.status === 404) {
      throw new Error('Customer not found');
    }
    if (err.response?.status === 401) {
      throw new Error('Authentication required');
    }
    console.error('Error deleting customer:', err);
    throw err;
  }
};
