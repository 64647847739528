import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

// Define the type for the user object
interface User {
  name?: string; // Optional property
}

const Dashboard: React.FC = () => {
  const { user } = useContext(AuthContext) as { user: User }; // Type the context

  return (
    <div className="container mx-auto px-6 py-8">
      <h3 className="text-gray-700 text-3xl font-medium">Welcome to your Dashboard</h3>
      <p className="mt-4">Welcome, {user?.name}</p>
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Total Customers</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">12,345</dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Total Items</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">23,456</dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Total Sales</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">$34,567</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
