import { useState, useEffect } from 'react';
import { fetchItems } from '../../../shared/ItemSearch/api/ItemAPI';
import { Item } from '../../../shared/ItemSearch/types/item';
import { GoldItem, SilverItem, VendorBill } from '../api/VendorBillAPI';

interface UseVendorBillFormProps {
  billId?: string;
}

const emptyGoldItem: GoldItem = {
  itemId: '',
  itemName: '',
  pcs: '0',
  grossWt: '0',
  lessWt: '0',
  netWt: '0',
  tunch: '0',
  wstg: '0',
  fine: '0'
};

const emptySilverItem: SilverItem = {
  fine: '0'
};

export const useVendorBillForm = ({ billId }: UseVendorBillFormProps) => {
  // Form state
  const [date, setDate] = useState<Date | null>(new Date());
  const [voucherNumber, setVoucherNumber] = useState<number>(0);
  const [selectedVendor, setSelectedVendor] = useState<{ _id: string; name: string } | null>(null);
  const [goldItems, setGoldItems] = useState<GoldItem[]>([{ ...emptyGoldItem }]);
  const [silverItems, setSilverItems] = useState<SilverItem[]>([{ ...emptySilverItem }]);
  const [outstandingGoldFine, setOutstandingGoldFine] = useState('0');
  const [outstandingSilverFine, setOutstandingSilverFine] = useState('0');
  
  // UI state
  const [items, setItems] = useState<Item[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const itemsData = await fetchItems();
        setItems(itemsData);
      } catch (error) {
        console.error('Error fetching items:', error);
        setError('Failed to load items');
      }
    };
    loadItems();
  }, []);

  // Calculate Outstanding Gold Fine
  useEffect(() => {
    const goldItemsFine = goldItems.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    setOutstandingGoldFine(goldItemsFine.toFixed(3));
  }, [goldItems]);

  // Calculate Outstanding Silver Fine
  useEffect(() => {
    const silverItemsFine = silverItems.reduce((sum, item) => sum + (parseFloat(item.fine) || 0), 0);
    setOutstandingSilverFine(silverItemsFine.toFixed(3));
  }, [silverItems]);

  const handleGoldItemChange = (newItems: GoldItem[]) => {
    setGoldItems(newItems);
  };

  const handleSilverItemChange = (newItems: SilverItem[]) => {
    setSilverItems(newItems);
  };

  const validateForm = (): string | null => {
    if (!date) {
      return 'Please select a date';
    }

    if (!selectedVendor) {
      return 'Please select a vendor';
    }

    // Check if there's at least one gold item with all required fields
    const hasValidGoldItem = goldItems.some(item => {
      const grossWt = parseFloat(item.grossWt) || 0;
      const lessWt = parseFloat(item.lessWt) || 0;
      const tunch = parseFloat(item.tunch) || 0;
      const wstg = parseFloat(item.wstg) || 0;

      return item.itemName && 
             grossWt > 0 && 
             (tunch > 0 || wstg > 0) && 
             (grossWt > lessWt);
    });

    if (!hasValidGoldItem) {
      return 'Please add at least one valid gold item with proper values';
    }

    return null;
  };

  const resetForm = () => {
    setDate(new Date());
    setSelectedVendor(null);
    setGoldItems([{ ...emptyGoldItem }]);
    setSilverItems([{ ...emptySilverItem }]);
    setOutstandingGoldFine('0');
    setOutstandingSilverFine('0');
    setError(null);
    setSuccessMessage(null);
  };

  return {
    // Form state
    date,
    setDate,
    voucherNumber,
    setVoucherNumber,
    selectedVendor,
    setSelectedVendor,
    goldItems,
    setGoldItems: handleGoldItemChange,
    silverItems,
    setSilverItems: handleSilverItemChange,
    outstandingGoldFine,
    outstandingSilverFine,
    
    // UI state
    items,
    isSubmitting,
    setIsSubmitting,
    error,
    setError,
    successMessage,
    setSuccessMessage,

    // Helper functions
    validateForm,
    resetForm
  };
};
