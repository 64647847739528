import React, { useCallback } from 'react';
import { ItemSearch } from '../../../shared/ItemSearch/components';
import { Item } from '../../../shared/ItemSearch/types/item';

interface LoanItem {
  itemId: string;
  itemName: string;
  type: string;
  pcs: string;
  grossWt: string;
  lessWt: string;
  netWt: string;
  tunch: string;
  wstg: string;
  fine: string;
  rate: string;
  amt: string;
}

interface ItemsLoanTableProps {
  items: LoanItem[];
  setItems: (items: LoanItem[]) => void;
  availableItems?: Item[];
}

const ItemsLoanTable: React.FC<ItemsLoanTableProps> = ({ 
  items, 
  setItems,
  availableItems = []
}) => {
  const calculateValues = useCallback((item: LoanItem): LoanItem => {
    const grossWt = parseFloat(item.grossWt) || 0;
    const lessWt = parseFloat(item.lessWt) || 0;
    const netWt = Math.max(0, grossWt - lessWt).toFixed(3);
    const tunch = parseFloat(item.tunch) || 0;
    const wstg = parseFloat(item.wstg) || 0;
    const fine = ((parseFloat(netWt) * (tunch + wstg)) / 100).toFixed(3);
    const rate = parseFloat(item.rate) || 0;
    const amt = (parseFloat(fine) * rate).toFixed(2);

    return {
      ...item,
      netWt,
      fine,
      amt
    };
  }, []);

  const handleInputChange = useCallback((index: number, field: keyof LoanItem, value: string) => {
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        return calculateValues(updatedItem);
      }
      return item;
    });
    setItems(updatedItems);
  }, [items, setItems, calculateValues]);

  const handleItemSearch = useCallback((index: number, searchTerm: string) => {
    const updatedItems = items.map((item, i) => 
      i === index ? { 
        ...item, 
        itemName: searchTerm,
        type: '',
        itemId: ''
      } : item
    );
    setItems(updatedItems);
  }, [items, setItems]);

  const handleItemSelect = useCallback((index: number, selectedItem: Item) => {
    const updatedItems = items.map((item, i) => 
      i === index ? calculateValues({
        ...item,
        itemId: selectedItem._id,
        itemName: selectedItem.name,
        type: selectedItem.type,
      }) : item
    );
    setItems(updatedItems);
  }, [items, setItems, calculateValues]);

  const addNewRow = () => {
    setItems([...items, {
      itemId: '',
      itemName: '',
      type: '',
      pcs: '0',
      grossWt: '0',
      lessWt: '0',
      netWt: '0',
      tunch: '0',
      wstg: '0',
      fine: '0',
      rate: '0',
      amt: '0'
    }]);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-6">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-40">Item Name</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-10">Pcs</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Gross Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Less Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Net Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Tunch</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Wstg</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Fine</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Rate</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Amt</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1 relative">
                <ItemSearch
                  selectedItem={{
                    itemId: item.itemId,
                    itemName: item.itemName,
                    type: item.type
                  }}
                  items={availableItems}
                  onItemSelect={(selectedItem) => handleItemSelect(index, selectedItem)}
                  onSearchChange={(searchTerm) => handleItemSearch(index, searchTerm)}
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  min="0"
                  step="1"
                  value={item.pcs === '0' ? '' : item.pcs}
                  onChange={(e) => handleInputChange(index, 'pcs', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={item.grossWt === '0' ? '' : item.grossWt}
                  onChange={(e) => handleInputChange(index, 'grossWt', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input 
                  type="text" 
                  value={item.lessWt === '0' ? '' : item.lessWt}
                  onChange={(e) => handleInputChange(index, 'lessWt', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1 bg-gray-50">
                {item.netWt === '0' ? '' : item.netWt}
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={item.tunch === '0' ? '' : item.tunch}
                  onChange={(e) => handleInputChange(index, 'tunch', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={item.wstg === '0' ? '' : item.wstg}
                  onChange={(e) => handleInputChange(index, 'wstg', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1 bg-gray-50">
                {item.fine === '0' ? '' : item.fine}
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={item.rate === '0' ? '' : item.rate}
                  onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1 bg-gray-50">
                {item.amt === '0' ? '' : item.amt}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default ItemsLoanTable;
