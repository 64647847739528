import axios from 'axios';

export interface VendorReportEntry {
  _id: string;
  vendorName: string;
  goldSummary: {
    totalBillFine: string;
    totalFineCut: string;
    outstandingFine: string;
  };
  silverSummary: {
    totalBillFine: string;
    totalFineCut: string;
    outstandingFine: string;
  };
  transactions: {
    _id: string;
    date: string;
    type: 'BILL' | 'FINE_CUT';
    voucherNumber: number;
    goldFine?: string;
    silverFine?: string;
    goldFineCut?: string;
    silverFineCut?: string;
  }[];
}

export const fetchVendorReport = async (): Promise<VendorReportEntry[]> => {
  try {
    const response = await axios.get<{ success: boolean; data: VendorReportEntry[] }>(
      `${import.meta.env.VITE_API_URL}/api/vendor-report`
    );

    if (!response.data.success) {
      throw new Error('Failed to fetch vendor report');
    }

    return response.data.data;
  } catch (error) {
    console.error('Error fetching vendor report:', error);
    throw error;
  }
};
