import React from 'react';
import VendorSearch from '../../../shared/vendorSearch/VendorSearch';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface Vendor {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
}

interface VendorBillFormHeaderProps {
  selectedVendor: Vendor | null;
  onVendorSelect: (vendor: Vendor | null) => void;
  date: Date | null;
  setDate: (date: Date | null) => void;
  voucherNumber: number;
}

const VendorBillFormHeader: React.FC<VendorBillFormHeaderProps> = ({
  selectedVendor,
  onVendorSelect,
  date,
  setDate,
  voucherNumber,
}) => {
  const handleDateChange = (date: Date | null) => {
    setDate(date);
  };

  return (
    <div className="flex flex-wrap -mx-2 mb-4">
      <div className="w-2/12 px-1 mb-6 md:mb-0">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="date">
          Date
        </label>
        <DatePicker
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholderText="Select date"
          id="date"
        />
      </div>

      <div className="w-6/12 px-1 mb-6 md:mb-0">
        <VendorSearch
          selectedVendor={selectedVendor}
          onVendorSelect={onVendorSelect}
        />
      </div>

      <div className="w-4/12 px-1 mb-6 md:mb-0 text-right">
        <div className="text-gray-700 font-bold">
          Voucher Number: {voucherNumber}
        </div>
      </div>
    </div>
  );
};

export default VendorBillFormHeader;
