import { Bill, BillItem, OldGoldSilverItem } from '../types/bill';

// Parse string to number safely
export const parseNumber = (value: string | number): number => {
  const parsed = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(parsed) ? 0 : parsed;
};

// Parse string to integer safely
export const parseInt = (value: string | number): number => {
  const parsed = typeof value === 'string' ? Number.parseInt(value, 10) : value;
  return isNaN(parsed) ? 0 : parsed;
};

// Format number to fixed decimal places
export const formatNumber = (value: number, decimals: number = 2): string => {
  return value.toFixed(decimals);
};

// Calculate net weight
export const calculateNetWeight = (grossWt: string, lessWt: string): number => {
  return parseNumber(grossWt) - parseNumber(lessWt);
};

// Calculate amount
export const calculateAmount = (netWt: number, rate: string): number => {
  return netWt * parseNumber(rate);
};

// Calculate labor amount
export const calculateLaborAmount = (amount: number, laborPercent: string): number => {
  return amount * (parseNumber(laborPercent) / 100);
};

// Calculate total amount for a bill item
export const calculateItemTotalAmount = (item: BillItem): number => {
  const amount = calculateAmount(item.netWt, item.rate);
  const laborAmount = calculateLaborAmount(amount, item.lbrPercent);
  const otherAmount = parseNumber(item.otherAmt);
  return amount + laborAmount + otherAmount;
};

// Calculate tax amount for a bill item
export const calculateItemTaxAmount = (totalAmount: number, taxPercent: string): number => {
  return totalAmount * (parseNumber(taxPercent) / 100);
};

// Calculate net amount for a bill item
export const calculateItemNetAmount = (item: BillItem): number => {
  const totalAmount = calculateItemTotalAmount(item);
  const taxAmount = calculateItemTaxAmount(totalAmount, item.taxPercent);
  return totalAmount + taxAmount;
};

// Calculate old gold/silver item amount
export const calculateOldItemAmount = (item: OldGoldSilverItem): number => {
  return parseNumber(item.weight) * parseNumber(item.rate);
};

// Calculate bill totals
export const calculateBillTotals = (
  items: BillItem[],
  oldItems: OldGoldSilverItem[] = [],
  isTaxEnabled: boolean,
  schemeAmount: string = '0',
  receivedAmount: string = '0',
  givenAmount: string = '0',
  roundOffAmount: string = '0',
  previousOutstanding: string = '0'
): Pick<Bill, 
  'itemsNetAmount' | 
  'taxAmount' | 
  'totalAmount' | 
  'oldGoldAmount' | 
  'oldSilverAmount' | 
  'osAmount'
> => {
  // Calculate items net amount
  const itemsNetAmount = items.reduce((total, item) => 
    total + calculateItemTotalAmount(item), 0);

  // Calculate tax amount if enabled
  const taxAmount = isTaxEnabled ? itemsNetAmount * 0.03 : 0; // 3% tax

  // Calculate total amount
  const totalAmount = itemsNetAmount + taxAmount;

  // Calculate old gold and silver amounts
  const oldGoldAmount = oldItems
    .filter(item => item.type.toLowerCase().includes('gold'))
    .reduce((total, item) => total + calculateOldItemAmount(item), 0);

  const oldSilverAmount = oldItems
    .filter(item => item.type.toLowerCase().includes('silver'))
    .reduce((total, item) => total + calculateOldItemAmount(item), 0);

  // Calculate outstanding amount
  const osAmount = 
    parseNumber(previousOutstanding) +
    totalAmount -
    oldGoldAmount -
    oldSilverAmount -
    parseNumber(schemeAmount) -
    parseNumber(receivedAmount) +
    parseNumber(givenAmount) -
    parseNumber(roundOffAmount);

  return {
    itemsNetAmount,
    taxAmount,
    totalAmount,
    oldGoldAmount,
    oldSilverAmount,
    osAmount
  };
};

// Recalculate bill item values while preserving identity fields
export const recalculateBillItem = (item: BillItem): BillItem => {
  // Preserve identity fields
  const {
    itemId,
    itemNameWithType,
    tagNo,
    grossWt,
    lessWt,
    rate,
    lbrPercent,
    otherAmt,
    taxPercent,
    pcs
  } = item;

  // Calculate dependent values
  const netWt = calculateNetWeight(grossWt, lessWt);
  const amt = calculateAmount(netWt, rate);
  const lbrAmt = calculateLaborAmount(amt, lbrPercent);
  const totAmt = amt + lbrAmt + parseNumber(otherAmt);
  const netAmt = totAmt * (1 + parseNumber(taxPercent) / 100);

  // Return new item with preserved fields and calculated values
  return {
    itemId,
    itemNameWithType,
    tagNo,
    pcs,
    grossWt,
    lessWt,
    netWt,
    rate,
    amt,
    lbrPercent,
    lbrAmt,
    otherAmt,
    totAmt,
    taxPercent,
    netAmt
  };
};

// Format currency
export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

// Validate decimal number input
export const isValidDecimalInput = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};
