import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Item {
  _id: string;
  name: string;
  type: string;
}

interface StockItem {
  _id: string;
  item: Item;
  quantity: number;
  date: string;
}

const Stock: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [stockItems, setStockItems] = useState<StockItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Get auth token from localStorage
  const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { headers: { 'x-auth-token': token || '' } };
  };

  useEffect(() => {
    fetchItems();
    fetchStockItems();
  }, []);

  const fetchItems = async () => {
    try {
      const res = await axios.get<Item[]>(`${import.meta.env.VITE_API_URL}/api/items`, getAuthHeader());
      setItems(res.data);
    } catch (err: any) {
      console.error('Error fetching items:', err);
      setError(err.response?.data?.msg || 'Error fetching items');
    }
  };

  const fetchStockItems = async () => {
    try {
      const res = await axios.get<StockItem[]>(`${import.meta.env.VITE_API_URL}/api/stock`, getAuthHeader());
      setStockItems(res.data);
    } catch (err: any) {
      console.error('Error fetching stock:', err);
      setError(err.response?.data?.msg || 'Error fetching stock');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}/api/stock`,
        { item: selectedItem, quantity: parseInt(quantity, 10) },
        getAuthHeader()
      );
      
      setSelectedItem('');
      setQuantity('');
      // Refresh stock items after adding new stock
      await fetchStockItems();
      alert('Stock added successfully!');
    } catch (err: any) {
      console.error('Error adding stock:', err);
      setError(err.response?.data?.msg || 'Error adding stock. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Stock Management</h1>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Add Stock Form */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Add Stock</h2>
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="item">
                  Item
                </label>
                <select
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="item"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                  required
                >
                  <option value="">Select an item</option>
                  {items.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name} - {item.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                  Quantity
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="quantity"
                  type="number"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Adding...' : 'Add Stock'}
                </button>
              </div>
            </form>
          </div>

          {/* Current Stock List */}
          <div>
            <h2 className="text-xl font-semibold mb-4">Current Stock</h2>
            <div className="bg-white shadow-md rounded overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Item
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Quantity
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Updated
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {stockItems.map((stock) => (
                    <tr key={stock._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {stock.item.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stock.item.type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {stock.quantity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(stock.date).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
