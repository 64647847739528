import axios from 'axios';
import { Customer, CustomerResponse, SingleCustomerResponse } from '../types/bill';

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Error handler with production-safe messages
const handleApiError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    // Handle specific error cases
    if (error.response?.status === 401) {
      throw new Error('Your session has expired. Please log in again.');
    }
    if (error.response?.status === 404) {
      throw new Error('No customers found.');
    }
    if (error.response?.status === 500) {
      throw new Error('An error occurred while processing your request. Please try again.');
    }

    throw new Error(
      error.response?.data?.error || 
      'An unexpected error occurred'
    );
  }
  throw error;
};

// Type guard for customer data
const isCustomerArray = (data: any): data is Customer[] => {
  return Array.isArray(data) && data.every(item => 
    typeof item === 'object' &&
    typeof item._id === 'string' &&
    typeof item.name === 'string'
  );
};

export const customerApi = {
  // Get all customers
  getCustomers: async (): Promise<Customer[]> => {
    try {
      const response = await api.get<CustomerResponse | Customer[]>('/customers');

      let customers: Customer[];

      // Handle different response formats
      if ('data' in response.data && 'success' in response.data) {
        // Response is CustomerResponse type
        if (!response.data.success) {
          throw new Error('Failed to fetch customers');
        }
        customers = response.data.data;
      } else if (Array.isArray(response.data)) {
        // Response is direct array
        customers = response.data;
      } else {
        throw new Error('Invalid response format');
      }

      // Validate customer data
      if (!isCustomerArray(customers)) {
        throw new Error('Invalid customer data received from server');
      }

      return customers;
    } catch (error) {
      handleApiError(error);
      return [];
    }
  },

  // Search customers - using the main endpoint and filtering in memory
  searchCustomers: async (searchTerm: string): Promise<Customer[]> => {
    try {
      const customers = await customerApi.getCustomers();

      // Filter customers based on search term
      return customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (customer.phone && customer.phone.includes(searchTerm)) ||
        (customer.address && customer.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (customer.city && customer.city.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    } catch (error) {
      handleApiError(error);
      return [];
    }
  },

  // Get customer by ID
  getCustomerById: async (id: string): Promise<Customer> => {
    try {
      const response = await api.get<SingleCustomerResponse>(`/customers/${id}`);

      if (!response.data.success) {
        throw new Error('Failed to fetch customer');
      }

      const customer = response.data.data;
      if (!customer || typeof customer.name !== 'string') {
        throw new Error('Invalid customer data received');
      }

      return customer;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }
};
