import React, { useState } from 'react';
import { Dialog, Tab } from '@headlessui/react';
import { VendorReportEntry } from './VendorReportAPI';

interface VendorStatementPopupProps {
  isOpen: boolean;
  onClose: () => void;
  vendor: VendorReportEntry;
}

const VendorStatementPopup: React.FC<VendorStatementPopupProps> = ({
  isOpen,
  onClose,
  vendor,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN');
  };

  // Calculate running balances
  const calculateRunningBalances = (transactions: VendorReportEntry['transactions'], isGold: boolean) => {
    let balance = 0;
    return transactions.map(transaction => {
      if (transaction.type === 'BILL') {
        balance += parseFloat(isGold ? transaction.goldFine || '0' : transaction.silverFine || '0');
      } else {
        balance -= parseFloat(isGold ? transaction.goldFineCut || '0' : transaction.silverFineCut || '0');
      }
      return { ...transaction, balance };
    });
  };

  const goldTransactions = calculateRunningBalances(vendor.transactions, true);
  const silverTransactions = calculateRunningBalances(vendor.transactions, false);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <Dialog.Panel className="relative bg-white rounded-lg max-w-4xl w-full p-6 shadow-xl">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-bold text-gray-900">
              Statement of Account - {vendor.vendorName}
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
            <Tab.List className="flex space-x-4 border-b mb-4">
              <Tab className={({ selected }) =>
                `px-4 py-2 text-sm font-medium leading-5 ${
                  selected
                    ? 'text-yellow-600 border-b-2 border-yellow-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`
              }>
                Gold
              </Tab>
              <Tab className={({ selected }) =>
                `px-4 py-2 text-sm font-medium leading-5 ${
                  selected
                    ? 'text-gray-600 border-b-2 border-gray-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`
              }>
                Silver
              </Tab>
            </Tab.List>

            <Tab.Panels>
              {/* Gold Panel */}
              <Tab.Panel>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Voucher
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Debit
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Credit
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {goldTransactions.map((transaction) => (
                        <tr key={transaction._id} className="hover:bg-gray-50">
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {formatDate(transaction.date)}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {transaction.voucherNumber}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {transaction.type === 'BILL' ? 'Bill' : 'Fine Cut'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                            {transaction.type === 'BILL' ? transaction.goldFine : '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-green-600 text-right">
                            {transaction.type === 'FINE_CUT' ? transaction.goldFineCut : '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-right">
                            {transaction.balance.toFixed(3)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-6 border-t pt-4 space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="font-medium">Total Bills:</span>
                    <span className="text-gray-900">{vendor.goldSummary.totalBillFine}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="font-medium">Total Fine Cuts:</span>
                    <span className="text-green-600">{vendor.goldSummary.totalFineCut}</span>
                  </div>
                  <div className="flex justify-between text-sm font-bold border-t pt-2">
                    <span>Current Outstanding:</span>
                    <span className="text-yellow-600">{vendor.goldSummary.outstandingFine}</span>
                  </div>
                </div>
              </Tab.Panel>

              {/* Silver Panel */}
              <Tab.Panel>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Voucher
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Debit
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Credit
                        </th>
                        <th className="px-4 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {silverTransactions.map((transaction) => (
                        <tr key={transaction._id} className="hover:bg-gray-50">
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {formatDate(transaction.date)}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {transaction.voucherNumber}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                            {transaction.type === 'BILL' ? 'Bill' : 'Fine Cut'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right">
                            {transaction.type === 'BILL' ? transaction.silverFine : '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-green-600 text-right">
                            {transaction.type === 'FINE_CUT' ? transaction.silverFineCut : '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-right">
                            {transaction.balance.toFixed(3)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-6 border-t pt-4 space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="font-medium">Total Bills:</span>
                    <span className="text-gray-900">{vendor.silverSummary.totalBillFine}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="font-medium">Total Fine Cuts:</span>
                    <span className="text-green-600">{vendor.silverSummary.totalFineCut}</span>
                  </div>
                  <div className="flex justify-between text-sm font-bold border-t pt-2">
                    <span>Current Outstanding:</span>
                    <span className="text-gray-600">{vendor.silverSummary.outstandingFine}</span>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default VendorStatementPopup;
