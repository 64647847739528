import React from 'react';
import CustomerSearch from './CustomerSearch';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
}

interface LoanIssueHeaderProps {
  customerName: string;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  customers: Customer[];
  filteredCustomers: Customer[];
  setFilteredCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  isCustomerSearchFocused: boolean;
  setIsCustomerSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleCustomerSelect: (customer: Customer) => void;
  date: Date | null;
  setDate: (date: Date | null) => void;
  voucherNumber: number;
  voucherNumberRef: React.RefObject<HTMLInputElement>;
}

const LoanIssueHeader: React.FC<LoanIssueHeaderProps> = ({
  customerName,
  setCustomerName,
  customers,
  filteredCustomers,
  setFilteredCustomers,
  isCustomerSearchFocused,
  setIsCustomerSearchFocused,
  handleCustomerSelect,
  date,
  setDate,
  voucherNumber,
  voucherNumberRef,
}) => {
  const handleDateChange = (date: Date | null) => {
    setDate(date);
  };

  return (
    <div className="flex flex-wrap -mx-2 mb-4">
      <div className="w-2/12 px-1 mb-6 md:mb-0">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="date">
          Date
        </label>
        <DatePicker
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholderText="Select date"
          id="date"
        />
      </div>

      <div className="w-6/12 px-1 mb-6 md:mb-0">
        <CustomerSearch
          customerName={customerName}
          setCustomerName={setCustomerName}
          customers={customers || []}
          filteredCustomers={filteredCustomers}
          setFilteredCustomers={setFilteredCustomers}
          isCustomerSearchFocused={isCustomerSearchFocused}
          setIsCustomerSearchFocused={setIsCustomerSearchFocused}
          handleCustomerSelect={handleCustomerSelect}
          voucherNumberRef={voucherNumberRef}
        />
      </div>

      <div className="w-4/12 px-1 mb-6 md:mb-0 text-right">
        <div className="text-gray-700 font-bold" ref={voucherNumberRef}>
          Voucher Number: {voucherNumber}
        </div>
      </div>
    </div>
  );
};

export default LoanIssueHeader;
