import React from 'react';

interface MoneyReceivedRow {
  amount: string;
  note: string;
}

interface MoneyReceivedTableProps {
  rows: MoneyReceivedRow[];
  setRows: React.Dispatch<React.SetStateAction<MoneyReceivedRow[]>>;
}

const MoneyReceivedTable: React.FC<MoneyReceivedTableProps> = ({
  rows,
  setRows,
}) => {
  const handleRowChange = (index: number, field: keyof MoneyReceivedRow, value: string) => {
    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { amount: '', note: '' }]);
  };

  return (
    <div className="mb-4">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <colgroup>
          <col className="w-10 sm:w-5" />
          <col className="w-1/2" />
          <col className="w-1/2" />
        </colgroup>
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Amount</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Note</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1 text-center">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={row.amount}
                  onChange={(e) => handleRowChange(index, 'amount', e.target.value)}
                  className="w-full px-2 py-1 focus:outline-none"
                  placeholder="Enter amount"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={row.note}
                  onChange={(e) => handleRowChange(index, 'note', e.target.value)}
                  className="w-full px-2 py-1 focus:outline-none"
                  placeholder="Enter note"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={handleAddRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
      >
        +
      </button>
    </div>
  );
};

export default MoneyReceivedTable;
