import React, { useState, useEffect, useContext, ChangeEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import VendorList from '../features/vendors/VendorList';
import VendorForm from '../features/vendors/VendorForm';
import { fetchVendors, createVendor, updateVendor, deleteVendor } from '../features/vendors/api';
import { AuthContext } from '../contexts/AuthContext';

const Vendors: React.FC = () => {
  const [vendors, setVendors] = useState<any[]>([]);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [pinCode, setPinCode] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [editingVendor, setEditingVendor] = useState<any | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const authContext = useContext(AuthContext);
  const user = authContext?.user;
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchVendorsData();
    }
  }, [user, navigate]);

  const fetchVendorsData = async () => {
    try {
      setLoading(true);
      const data = await fetchVendors();
      setVendors(data);
      setError('');
    } catch (err: any) {
      console.error('Error fetching vendors:', err);
      if (err.response && err.response.status === 401) {
        setError('Authentication error. Please log in again.');
        navigate('/login');
      } else {
        setError('Failed to fetch vendors. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      const vendorData = {
        name,
        phone,
        address,
        city,
        state,
        pinCode,
        note
      };
      if (editingVendor) {
        await updateVendor(editingVendor._id, vendorData);
      } else {
        await createVendor(vendorData);
      }
      resetForm();
      fetchVendorsData();
    } catch (err: any) {
      console.error('Error saving vendor:', err);
      setError('Failed to save vendor. Please try again.');
    }
  };

  const handleEdit = (vendor: any) => {
    setEditingVendor(vendor);
    setName(vendor.name);
    setPhone(vendor.phone);
    setAddress(vendor.address);
    setCity(vendor.city || '');
    setState(vendor.state || '');
    setPinCode(vendor.pinCode || '');
    setNote(vendor.note || '');
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this vendor?')) {
      try {
        await deleteVendor(id);
        fetchVendorsData();
      } catch (err: any) {
        console.error('Error deleting vendor:', err);
        setError('Failed to delete vendor. Please try again.');
      }
    }
  };

  const resetForm = () => {
    setName('');
    setPhone('');
    setAddress('');
    setCity('');
    setState('');
    setPinCode('');
    setNote('');
    setEditingVendor(null);
  };

  const capitalizeWords = (string: string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const capitalizedName = capitalizeWords(e.target.value);
    setName(capitalizedName);
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 ">
      <div className="mx-auto py-6 sm:px-6 lg:px-8 ">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <div className="flex flex-col md:flex-row ">

          {/* Vendor List Section */}
          <div className="w-full md:w-3/5 pr-0 md:pr-4 mb-6 md:mb-0">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Vendor List</h2>
            <VendorList 
              vendors={vendors}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </div>

          {/* New Vendor Section */}
          <div className="w-full md:w-2/5 pl-0 md:pl-4">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {editingVendor ? 'Edit Vendor' : 'New Vendor'}
            </h2>
            <VendorForm
              name={name}
              phone={phone}
              address={address}
              city={city}
              state={state}
              pinCode={pinCode}
              note={note}
              editingVendor={editingVendor}
              onSubmit={handleSubmit}
              onNameChange={handleNameChange}
              onPhoneChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
              onAddressChange={(e: ChangeEvent<HTMLTextAreaElement>) => setAddress(e.target.value)}
              onCityChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
              onStateChange={(e: React.ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
              onPinCodeChange={(e: React.ChangeEvent<HTMLInputElement>) => setPinCode(e.target.value)}
              onNoteChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value)}
              onReset={resetForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendors;
