import React from 'react';
import { SilverItem } from '../api/VendorBillAPI';

export interface SilverItemsTableProps {
  tableItems: SilverItem[];
  setTableItems: (items: SilverItem[]) => void;
}

const emptyItem: SilverItem = {
  fine: ''
};

const SilverItemsTable: React.FC<SilverItemsTableProps> = ({ tableItems, setTableItems }) => {
  const addNewRow = () => {
    setTableItems([...tableItems, { ...emptyItem }]);
  };

  const handleInputChange = (index: number, field: keyof SilverItem, value: string) => {
    const newItems = [...tableItems];
    newItems[index] = { ...newItems[index], [field]: value };
    setTableItems(newItems);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Fine</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.fine}
                  onChange={(e) => handleInputChange(index, 'fine', e.target.value)}
                  className="w-full text-base"
                  step="0.001"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default SilverItemsTable;
