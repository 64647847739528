import React, { useState } from 'react';
import { Customer } from './CustomerForm';

interface CustomerListProps {
  customers: Customer[];
  onEdit: (customer: Customer) => void;
  onDelete: (customerId: string) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({ customers, onEdit, onDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.phone.includes(searchTerm) ||
    (customer.note && customer.note.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="bg-white shadow-md rounded px-4 py-4 overflow-x-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by customer name, phone number or note"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Contact
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Address
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              City
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Credit Limit
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Note
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer) => (
            <tr key={customer._id}>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 font-medium text-gray-900">{customer.name}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{customer.phone}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{customer.address}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{customer.city}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-900">
                  {customer.creditLimit ? `₹${customer.creditLimit.toLocaleString()}` : '-'}
                </div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{customer.note || ''}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <button
                  onClick={() => onEdit(customer)}
                  className="text-indigo-600 hover:text-indigo-900 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => onDelete(customer._id)}
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerList;
