import React, { useRef, useState, useCallback } from 'react';
import { useSidebar } from '../../../contexts/SidebarContext';
import LoanIssueHeader from './LoanIssueHeader';
import ItemsLoanTable from './ItemsLoanTable';
import LoanDetailsTable from './LoanDetailsTable';
import NavigationFooter from './NavigationFooter';
import PreviousLoans from './PreviousLoans';
import { useLoanForm } from '../hooks/useLoanForm';
import { Customer } from '../types/loanTypes';
import { getLoan, getNearestPreviousLoan, getNearestNextLoan } from '../api/LoanAPI';
import "react-datepicker/dist/react-datepicker.css";

interface LoanIssueFormProps {
  loanId?: string;
}

const LoanIssueForm: React.FC<LoanIssueFormProps> = ({ loanId }) => {
  const { isOpen: isSidebarOpen } = useSidebar();
  const [customerName, setCustomerName] = useState('');
  const [selectedCustomerObj, setSelectedCustomerObj] = useState<Customer | null>(null);
  const [isCustomerSearchFocused, setIsCustomerSearchFocused] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [isPreviousLoansOpen, setIsPreviousLoansOpen] = useState(false);
  const [editingLoanId, setEditingLoanId] = useState<string | null>(null);

  const {
    loanState,
    setLoanState,
    customers,
    items,
    handleCustomerSelect: handleCustomerSelectHook,
    handleSubmit: originalHandleSubmit,
    handleItemChange,
    handleLoanDetailChange,
    isLoading,
    error
  } = useLoanForm({ loanId: editingLoanId || loanId });

  const voucherNumberRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      await originalHandleSubmit(e);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  };

  const handleCustomerSelect = useCallback((customer: Customer) => {
    setSelectedCustomerObj(customer);
    handleCustomerSelectHook(customer);
    setCustomerName(customer.name);
  }, [handleCustomerSelectHook]);

  const setCustomerNameHandler = useCallback((value: React.SetStateAction<string>) => {
    const newValue = typeof value === 'function' ? value(customerName) : value;
    setCustomerName(newValue);
  }, [customerName]);

  const handleEdit = async (editLoanId: string) => {
    try {
      setEditingLoanId(editLoanId);
      const response = await getLoan(editLoanId);
      if (response.success && response.data) {
        const loanData = response.data;
        const customer = customers.find(c => c._id === (typeof loanData.customer === 'string' ? loanData.customer : loanData.customer._id));
        
        if (!customer) {
          console.error('Customer not found:', loanData.customer);
          return;
        }

        setCustomerName(customer.name);
        setSelectedCustomerObj(customer);
        setFilteredCustomers([customer]);
        setLoanState(loanData);
        handleCustomerSelect(customer);
        setIsCustomerSearchFocused(false);
      }
    } catch (error) {
      console.error('Error loading loan for edit:', error);
    }
  };

  const handlePreviousLoan = async () => {
    if (loanState.voucherNumber > 1) {
      try {
        const response = await getNearestPreviousLoan(loanState.voucherNumber);
        if (response.success && response.data && response.data._id) {
          handleEdit(response.data._id);
        }
      } catch (err) {
        console.error('Error fetching previous loan:', err);
      }
    }
  };

  const handleNextLoan = async () => {
    try {
      const response = await getNearestNextLoan(loanState.voucherNumber);
      if (response.success && response.data && response.data._id) {
        handleEdit(response.data._id);
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error('Error fetching next loan:', err);
      window.location.reload();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-24 mb-4 relative">
      <LoanIssueHeader
        customerName={customerName}
        setCustomerName={setCustomerNameHandler}
        customers={customers}
        filteredCustomers={filteredCustomers}
        setFilteredCustomers={setFilteredCustomers}
        isCustomerSearchFocused={isCustomerSearchFocused}
        setIsCustomerSearchFocused={setIsCustomerSearchFocused}
        handleCustomerSelect={handleCustomerSelect}
        date={loanState.date}
        setDate={(date) => {
          if (date) {
            setLoanState(prev => ({ ...prev, date }));
          }
        }}
        voucherNumber={loanState.voucherNumber || 1}
        voucherNumberRef={voucherNumberRef}
      />

      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full px-3">
          <ItemsLoanTable
            items={loanState.items || []}
            setItems={handleItemChange}
            availableItems={items}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3">
          <LoanDetailsTable
            details={loanState.loanDetails || []}
            setDetails={handleLoanDetailChange}
          />
        </div>
      </div>

      <NavigationFooter
        voucherNumber={loanState.voucherNumber || 1}
        handlePreviousBill={handlePreviousLoan}
        handleNextBill={handleNextLoan}
        setIsPreviousBillsOpen={setIsPreviousLoansOpen}
        editingBillId={editingLoanId}
        isSidebarOpen={isSidebarOpen}
      />

      <PreviousLoans 
        isOpen={isPreviousLoansOpen}
        onClose={() => setIsPreviousLoansOpen(false)}
        onEdit={handleEdit}
      />
    </form>
  );
};

export default LoanIssueForm;
