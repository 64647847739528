import React, { useState, useRef, useEffect } from 'react';

interface Item {
  _id: string;
  name: string;
  type: string;
  rate?: string;
}

interface ItemSearchProps {
  selectedItem?: {
    itemId: string;
    itemName: string;
    type: string;
  };
  items: Item[];
  onItemSelect: (item: Item) => void;
  onSearchChange?: (searchTerm: string) => void;
  className?: string;
}

const ItemSearch: React.FC<ItemSearchProps> = ({
  selectedItem,
  items = [],
  onItemSelect,
  onSearchChange,
  className = ''
}) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    onSearchChange?.(value);
  };

  const handleItemSelect = (item: Item) => {
    onItemSelect(item);
    setIsSearchFocused(false);
    setSearchTerm('');
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
      setIsSearchFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={searchRef}>
      <div className="flex items-center">
        <input
          type="text"
          value={isSearchFocused ? searchTerm : ''}
          onChange={(e) => handleSearchChange(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
          placeholder={!isSearchFocused ? '' : 'Search items...'}
          className={`w-full ${!isSearchFocused ? 'absolute opacity-0 cursor-pointer' : ''}`}
        />
        {!isSearchFocused && (
          <div 
            className="w-full flex items-center cursor-pointer" 
            onClick={() => setIsSearchFocused(true)}
          >
            <span>{selectedItem?.itemName}</span>
            {selectedItem?.type && (
              <span className={`ml-1 px-2 py-1 rounded-full text-xs ${
                selectedItem.type === 'Gold' ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800'
              }`}>
                {selectedItem.type}
              </span>
            )}
          </div>
        )}
      </div>
      {isSearchFocused && items?.length > 0 && (
        <ul className="absolute z-10 bg-white border border-gray-300 w-full max-h-60 overflow-auto">
          {items
            .filter(item => {
              const searchString = `${item.name} ${item.type}`.toLowerCase();
              return searchString.includes(searchTerm.toLowerCase());
            })
            .map((item) => (
              <li
                key={item._id}
                className="px-1 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleItemSelect(item)}
              >
                <span>{item.name}</span>
                <span className={`ml-1 px-2 py-1 rounded-full text-xs ${
                  item.type === 'Gold' ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800'
                }`}>
                  {item.type}
                </span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default ItemSearch;
