import React from 'react';
import { SilverFineCutCashItem } from './VendorFineCutAPI';

export interface SilverFineCutCashTableProps {
  tableItems: SilverFineCutCashItem[];
  setTableItems: (items: SilverFineCutCashItem[]) => void;
}

const emptyItem: SilverFineCutCashItem = {
  amt: '',
  rate: '',
  fine: ''
};

const SilverFineCutCashTable: React.FC<SilverFineCutCashTableProps> = ({ tableItems, setTableItems }) => {
  const addNewRow = () => {
    setTableItems([...tableItems, { ...emptyItem }]);
  };

  const handleInputChange = (index: number, field: keyof SilverFineCutCashItem, value: string) => {
    const newItems = [...tableItems];
    newItems[index] = { ...newItems[index], [field]: value };

    // Calculate Fine
    if (field === 'amt' || field === 'rate') {
      const amt = parseFloat(newItems[index].amt) || 0;
      const rate = parseFloat(newItems[index].rate) || 0;
      if (rate > 0) {
        const fine = amt / rate;
        newItems[index].fine = fine.toFixed(3);
      }
    }

    setTableItems(newItems);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Amt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Rate</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Fine</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.amt}
                  onChange={(e) => handleInputChange(index, 'amt', e.target.value)}
                  className="w-full text-base"
                  step="0.01"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.rate}
                  onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                  className="w-full text-base"
                  step="0.01"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.fine}
                  readOnly
                  className="w-full text-base bg-gray-100"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default SilverFineCutCashTable;
