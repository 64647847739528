import React, { useState, useEffect } from 'react';
import { Bill } from '../../types/bill';
import { billApi } from '../../api/billApi';
import { useBillContext, billActions } from '../../contexts/BillContext';

interface PreviousBillsProps {
  onEdit: (billId: string) => void;
}

export const PreviousBills: React.FC<PreviousBillsProps> = ({
  onEdit,
}) => {
  const { state, dispatch } = useBillContext();
  const [bills, setBills] = useState<Bill[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(billActions.setPreviousBillsVisible(false));
  };

  useEffect(() => {
    const loadBills = async () => {
      if (!state.isPreviousBillsVisible) return;
      
      try {
        setLoading(true);
        const response = await billApi.fetchBills();
        // Sort bills by voucher number in descending order
        const sortedBills = [...response.bills].sort((a, b) => b.voucherNumber - a.voucherNumber);
        setBills(sortedBills);
      } catch (err) {
        setError('Failed to load bills');
        console.error('Error loading bills:', err);
      } finally {
        setLoading(false);
      }
    };

    loadBills();
  }, [state.isPreviousBillsVisible]);

  const handleDelete = async (billId: string) => {
    if (!window.confirm('Are you sure you want to delete this bill?')) {
      return;
    }

    try {
      dispatch(billActions.setLoading(true));
      await billApi.deleteBill(billId);
      setBills(prevBills => prevBills.filter(bill => bill._id !== billId));
    } catch (err) {
      setError('Failed to delete bill');
      console.error('Error deleting bill:', err);
    } finally {
      dispatch(billActions.setLoading(false));
    }
  };

  if (!state.isPreviousBillsVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 w-3/4 max-h-[80vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold">Previous Bills</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-5 w-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {loading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        )}

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-4">
            {error}
          </div>
        )}

        <div className="overflow-auto flex-grow">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-1 text-left">Voucher No.</th>
                <th className="border border-gray-300 p-1 text-left">Date</th>
                <th className="border border-gray-300 p-1 text-left">Customer</th>
                <th className="border border-gray-300 p-1 text-right">Total Amount</th>
                <th className="border border-gray-300 p-1 text-center">Items</th>
                <th className="border border-gray-300 p-1 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bills.map((bill) => (
                <tr key={bill._id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 p-1">
                    {bill.voucherNumber}
                  </td>
                  <td className="border border-gray-300 p-1">
                    {new Date(bill.date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-1">
                    {bill.selectedCustomer}
                  </td>
                  <td className="border border-gray-300 p-1 text-right">
                    ₹{bill.totalAmount.toFixed(2)}
                  </td>
                  <td className="border border-gray-300 p-1 text-center">
                    {bill.items.length}
                  </td>
                  <td className="border border-gray-300 p-1">
                    <div className="flex justify-center gap-4">
                      <button
                        onClick={() => {
                          if (bill._id) {
                            onEdit(bill._id);
                            handleClose();
                          }
                        }}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => bill._id && handleDelete(bill._id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
