export interface BillItem {
  tagNo?: string;
  itemId: string | { _id: string; name: string; type: string };  // Modified to handle both string and object
  itemNameWithType: string;
  pcs: number;
  grossWt: string;
  lessWt: string;
  netWt: number;
  rate: string;
  amt: number;
  lbrPercent: string;
  lbrAmt: number;
  otherAmt: string;
  totAmt: number;
  taxPercent: string;
  netAmt: number;
}

export interface OldGoldSilverItem {
  type: string;
  itemId: string | { _id: string; name: string; type: string };  // Modified to handle both string and object
  itemNameWithType: string;
  pcs: number;
  weight: string;
  rate: string;
  amt: number;
}

export interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  pinCode?: string;
  creditLimit?: number;
  referredBy?: string;
  outstandingAmount?: number;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

export interface Bill {
  _id?: string;
  customer: string | { _id: string; name: string };  // Modified to handle both string and object
  selectedCustomer: string;
  previousOutstanding: string;
  date: Date;
  voucherNumber: number;
  items: BillItem[];
  oldGoldSilverItems?: OldGoldSilverItem[];
  itemsNetAmount: number;
  taxAmount?: number;
  isTaxEnabled: boolean;
  totalAmount: number;
  oldGoldAmount?: number;
  oldSilverAmount?: number;
  schemeAmount?: string;
  receivedAmount?: string;
  givenAmount?: string;
  roundOffAmount?: string;
  osAmount?: number;
  createdAt?: string;
  updatedAt?: string;
  userId?: string;
  __v?: number;  // Added version field for optimistic concurrency
}

export interface BillFormState {
  bill: Bill;
  isLoading: boolean;
  error: string | null;
  isDirty: boolean;
}

export interface BillContextState extends BillFormState {
  customers: Customer[];
  filteredCustomers: Customer[];
  isCustomerSearchFocused: boolean;
  isPreviousBillsVisible: boolean;
  customersLoaded: boolean;
}

// API Response Types
export interface ApiResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

export interface CustomerResponse {
  success: boolean;
  data: Customer[];
}

export interface SingleCustomerResponse {
  success: boolean;
  data: Customer;
}

export interface BillResponse {
  success: boolean;
  data: Bill;
}

export interface BillsResponse {
  success: boolean;
  data: {
    bills: Bill[];
    nextVoucherNumber: number;
    pagination: {
      total: number;
      page: number;
      pages: number;
    };
  };
}

// Validation helper types
export interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

export interface BillValidation {
  hasValidRegularItems: boolean;
  hasValidOldItems: boolean;
  errors: string[];
}

// Default values
export const DEFAULT_BILL_ITEM: BillItem = {
  tagNo: '',
  itemId: '',
  itemNameWithType: '',
  pcs: 0,
  grossWt: '0',
  lessWt: '0',
  netWt: 0,
  rate: '0',
  amt: 0,
  lbrPercent: '0',
  lbrAmt: 0,
  otherAmt: '0',
  totAmt: 0,
  taxPercent: '0',
  netAmt: 0
};

export const DEFAULT_OLD_ITEM: OldGoldSilverItem = {
  type: 'Old Gold',
  itemId: '',
  itemNameWithType: '',
  pcs: 0,
  weight: '0',
  rate: '0',
  amt: 0
};

// Helper function to get itemId value
const getItemId = (itemId: string | { _id: string; name: string; type: string }): string => {
  if (typeof itemId === 'string') return itemId;
  return itemId._id || '';
};

// Validation helpers
export const isValidBillItem = (item: BillItem): boolean => {
  const id = getItemId(item.itemId);
  return (
    item.itemNameWithType.trim() !== '' &&
    id.trim() !== '' &&
    item.netWt > 0 &&
    item.totAmt > 0
  );
};

export const isValidOldItem = (item: OldGoldSilverItem): boolean => {
  const id = getItemId(item.itemId);
  return (
    item.itemNameWithType.trim() !== '' &&
    id.trim() !== '' &&
    parseFloat(item.weight) > 0 &&
    item.amt > 0
  );
};
