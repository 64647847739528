import { useEffect, useState, useCallback } from 'react';
import { useBillContext, billActions } from '../contexts/BillContext';
import { billApi } from '../api/billApi';
import { Customer, BillItem, OldGoldSilverItem, isValidBillItem, isValidOldItem } from '../types/bill';
import { calculateBillTotals, recalculateBillItem, calculateOldItemAmount } from '../utils/calculations';
import { DEFAULT_BILL_ITEM, DEFAULT_OLD_ITEM } from '../types/bill';
import { SelectedItem } from '../shared/ItemSearch/types/item';
import { parseISO } from 'date-fns';

export type BillItemStringFields = keyof Pick<BillItem, 'itemId' | 'itemNameWithType' | 'tagNo' | 'grossWt' | 'lessWt' | 'rate' | 'lbrPercent' | 'otherAmt' | 'taxPercent'>;
export type OldItemStringFields = keyof Pick<OldGoldSilverItem, 'itemId' | 'itemNameWithType' | 'type' | 'weight' | 'rate'>;

const getInitialBillState = (voucherNumber = 1) => ({
  customer: '',
  selectedCustomer: '',
  previousOutstanding: '0',
  date: new Date(),
  voucherNumber,
  items: [{ ...DEFAULT_BILL_ITEM }],
  oldGoldSilverItems: [{ ...DEFAULT_OLD_ITEM }],
  itemsNetAmount: 0,
  isTaxEnabled: true,
  taxAmount: 0,
  totalAmount: 0,
  oldGoldAmount: 0,
  oldSilverAmount: 0,
  schemeAmount: '0',
  receivedAmount: '0',
  givenAmount: '0',
  roundOffAmount: '0',
  osAmount: 0,
  __v: 0
});

const ensureDateObject = (date: Date | string): Date => {
  if (date instanceof Date) return date;
  if (typeof date === 'string') return parseISO(date);
  return new Date();
};

const transformItemForSubmission = (item: BillItem): BillItem => ({
  ...item,
  itemId: typeof item.itemId === 'string' ? item.itemId : item.itemId._id
});

const transformOldItemForSubmission = (item: OldGoldSilverItem): OldGoldSilverItem => ({
  ...item,
  itemId: typeof item.itemId === 'string' ? item.itemId : item.itemId._id
});

const transformCustomerForSubmission = (customer: string | { _id: string; name: string }) => {
  if (typeof customer === 'string') return customer;
  return customer._id;
};

export const useBillForm = (initialBillId?: string) => {
  const { state, dispatch } = useBillContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentBillId, setCurrentBillId] = useState<string | undefined>(initialBillId);

  // Load initial bill only once when initialBillId changes
  useEffect(() => {
    let mounted = true;
    
    if (initialBillId) {
      (async () => {
        try {
          setIsLoading(true);
          const billData = await billApi.fetchBillDetails(initialBillId);
          if (mounted) {
            dispatch(billActions.setBill({
              ...billData,
              date: ensureDateObject(billData.date)
            }));
            setCurrentBillId(initialBillId);
          }
        } catch (err) {
          if (mounted) {
            setError(err instanceof Error ? err.message : 'Failed to load bill');
          }
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [initialBillId, dispatch]);

  const resetForm = useCallback((nextVoucherNumber: number) => {
    dispatch(billActions.setBill(getInitialBillState(nextVoucherNumber)));
    setCurrentBillId(undefined);
  }, [dispatch]);

  const handleCustomerSelect = useCallback((customer: Customer) => {
    if (!customer._id || !customer.name) return;

    dispatch(billActions.setBill({
      ...state.bill,
      customer: customer._id,
      selectedCustomer: customer.name,
      previousOutstanding: customer.outstandingAmount?.toString() || '0'
    }));
  }, [dispatch, state.bill]);

  const handleBillItemSelect = useCallback((index: number, selectedItem: SelectedItem) => {
    const updatedItems = [...state.bill.items];
    updatedItems[index] = {
      ...updatedItems[index],
      itemId: selectedItem.itemId,
      itemNameWithType: selectedItem.itemNameWithType
    };
    
    dispatch(billActions.updateBillItems(updatedItems));

    const totals = calculateBillTotals(
      updatedItems,
      state.bill.oldGoldSilverItems || [],
      state.bill.isTaxEnabled,
      state.bill.schemeAmount,
      state.bill.receivedAmount,
      state.bill.givenAmount,
      state.bill.roundOffAmount,
      state.bill.previousOutstanding
    );
    dispatch(billActions.updateTotals(totals));
  }, [dispatch, state.bill]);

  const handleBillItemChange = useCallback((index: number, field: BillItemStringFields, value: string) => {
    const updatedItems = [...state.bill.items];
    const currentItem = { ...updatedItems[index] };
    
    currentItem[field] = value;
    
    if (['grossWt', 'lessWt', 'rate', 'lbrPercent', 'otherAmt', 'taxPercent'].includes(field)) {
      Object.assign(currentItem, recalculateBillItem(currentItem));
    }
    
    updatedItems[index] = currentItem;
    dispatch(billActions.updateBillItems(updatedItems));

    const totals = calculateBillTotals(
      updatedItems,
      state.bill.oldGoldSilverItems || [],
      state.bill.isTaxEnabled,
      state.bill.schemeAmount,
      state.bill.receivedAmount,
      state.bill.givenAmount,
      state.bill.roundOffAmount,
      state.bill.previousOutstanding
    );
    dispatch(billActions.updateTotals(totals));
  }, [dispatch, state.bill]);

  const handleOldItemSelect = useCallback((index: number, selectedItem: SelectedItem) => {
    const updatedItems = [...(state.bill.oldGoldSilverItems || [])];
    updatedItems[index] = {
      ...updatedItems[index],
      itemId: selectedItem.itemId,
      itemNameWithType: selectedItem.itemNameWithType
    };
    
    dispatch(billActions.updateOldItems(updatedItems));

    const totals = calculateBillTotals(
      state.bill.items,
      updatedItems,
      state.bill.isTaxEnabled,
      state.bill.schemeAmount,
      state.bill.receivedAmount,
      state.bill.givenAmount,
      state.bill.roundOffAmount,
      state.bill.previousOutstanding
    );
    dispatch(billActions.updateTotals(totals));
  }, [dispatch, state.bill]);

  const handleOldItemChange = useCallback((index: number, field: OldItemStringFields, value: string) => {
    const updatedItems = [...(state.bill.oldGoldSilverItems || [])];
    const currentItem = { ...updatedItems[index] };
    
    currentItem[field] = value;
    
    if (field === 'weight' || field === 'rate') {
      currentItem.amt = calculateOldItemAmount(currentItem);
    }
    
    updatedItems[index] = currentItem;
    dispatch(billActions.updateOldItems(updatedItems));

    const totals = calculateBillTotals(
      state.bill.items,
      updatedItems,
      state.bill.isTaxEnabled,
      state.bill.schemeAmount,
      state.bill.receivedAmount,
      state.bill.givenAmount,
      state.bill.roundOffAmount,
      state.bill.previousOutstanding
    );
    dispatch(billActions.updateTotals(totals));
  }, [dispatch, state.bill]);

  const handleDateChange = useCallback((date: Date) => {
    dispatch(billActions.setBill({
      ...state.bill,
      date
    }));
  }, [dispatch, state.bill]);

  const addBillItem = useCallback(() => {
    dispatch(billActions.updateBillItems([...state.bill.items, { ...DEFAULT_BILL_ITEM }]));
  }, [dispatch, state.bill.items]);

  const addOldItem = useCallback(() => {
    dispatch(billActions.updateOldItems([...(state.bill.oldGoldSilverItems || []), { ...DEFAULT_OLD_ITEM }]));
  }, [dispatch, state.bill.oldGoldSilverItems]);

  const validateBill = useCallback(() => {
    const validItems = state.bill.items.filter(isValidBillItem);
    const validOldItems = (state.bill.oldGoldSilverItems || []).filter(isValidOldItem);

    if (validItems.length === 0 && validOldItems.length === 0) {
      throw new Error('At least one valid item with name, ID, and weight is required');
    }

    return {
      validItems: validItems.map(transformItemForSubmission),
      validOldItems: validOldItems.map(transformOldItemForSubmission)
    };
  }, [state.bill]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { validItems, validOldItems } = validateBill();

      const billData = {
        ...state.bill,
        customer: transformCustomerForSubmission(state.bill.customer),
        schemeAmount: state.bill.schemeAmount || '0',
        receivedAmount: state.bill.receivedAmount || '0',
        givenAmount: state.bill.givenAmount || '0',
        roundOffAmount: state.bill.roundOffAmount || '0',
        previousOutstanding: state.bill.previousOutstanding || '0',
        items: validItems,
        oldGoldSilverItems: validOldItems,
        __v: state.bill.__v || 0
      };

      if (currentBillId) {
        await billApi.updateBill(currentBillId, billData);
        const { nextVoucherNumber } = await billApi.fetchBills();
        resetForm(nextVoucherNumber);
      } else {
        const { nextVoucherNumber } = await billApi.createBill(billData);
        resetForm(nextVoucherNumber);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save bill');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousBill = useCallback(async () => {
    if (!state.bill.voucherNumber) return;
    
    try {
      setIsLoading(true);
      setError(null);
      const previousBill = await billApi.fetchNearestPreviousBill(state.bill.voucherNumber);
      dispatch(billActions.setBill({
        ...previousBill,
        date: ensureDateObject(previousBill.date)
      }));
      setCurrentBillId(previousBill._id);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load previous bill';
      if (!errorMessage.includes('No previous bill found')) {
        setError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.bill.voucherNumber]);

  const handleNextBill = useCallback(async () => {
    if (!state.bill.voucherNumber) return;
    
    try {
      setIsLoading(true);
      setError(null);
      const nextBill = await billApi.fetchNearestNextBill(state.bill.voucherNumber);
      dispatch(billActions.setBill({
        ...nextBill,
        date: ensureDateObject(nextBill.date)
      }));
      setCurrentBillId(nextBill._id);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : '';
      if (errorMessage.includes('No next bill found')) {
        const { nextVoucherNumber } = await billApi.fetchBills();
        resetForm(nextVoucherNumber);
      } else {
        setError(errorMessage || 'Failed to load next bill');
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.bill.voucherNumber, resetForm]);

  const handleViewPreviousBills = useCallback(() => {
    dispatch(billActions.setPreviousBillsVisible(true));
  }, [dispatch]);

  return {
    bill: state.bill,
    isLoading,
    error,
    handleCustomerSelect,
    handleBillItemChange,
    handleBillItemSelect,
    handleOldItemChange,
    handleOldItemSelect,
    handleSubmit,
    handleDateChange,
    addBillItem,
    addOldItem,
    handlePreviousBill,
    handleNextBill,
    handleViewPreviousBills,
    resetForm
  };
};
