import React from 'react';
import { SilverFineCutOldItem } from './VendorFineCutAPI';

export interface SilverFineCutOldTableProps {
  tableItems: SilverFineCutOldItem[];
  setTableItems: (items: SilverFineCutOldItem[]) => void;
}

const emptyItem: SilverFineCutOldItem = {
  wt: '',
  tunch: '',
  fine: ''
};

const SilverFineCutOldTable: React.FC<SilverFineCutOldTableProps> = ({ tableItems, setTableItems }) => {
  const addNewRow = () => {
    setTableItems([...tableItems, { ...emptyItem }]);
  };

  const handleInputChange = (index: number, field: keyof SilverFineCutOldItem, value: string) => {
    const newItems = [...tableItems];
    newItems[index] = { ...newItems[index], [field]: value };

    // Calculate Fine
    if (field === 'wt' || field === 'tunch') {
      const wt = parseFloat(newItems[index].wt) || 0;
      const tunch = parseFloat(newItems[index].tunch) || 0;
      const fine = (wt * tunch) / 100;
      newItems[index].fine = fine.toFixed(3);
    }

    setTableItems(newItems);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Wt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Tunch</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Fine</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.wt}
                  onChange={(e) => handleInputChange(index, 'wt', e.target.value)}
                  className="w-full text-base"
                  step="0.001"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.tunch}
                  onChange={(e) => handleInputChange(index, 'tunch', e.target.value)}
                  className="w-full text-base"
                  step="0.01"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="number"
                  value={item.fine}
                  readOnly
                  className="w-full text-base bg-gray-100"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default SilverFineCutOldTable;
