import React from 'react';
import CustomerSearch from '../../customerBill/components/CustomerSearch';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Use the simpler Customer interface matching CustomerSearch component
interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
}

interface MoneyReceivedHeaderProps {
  customerName: string;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  customers: Customer[];
  filteredCustomers: Customer[];
  setFilteredCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  isCustomerSearchFocused: boolean;
  setIsCustomerSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
  handleCustomerSelect: (customer: Customer) => void;
  setPreviousOutstanding: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  previousOutstanding: number;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  voucherNumberRef: React.RefObject<HTMLInputElement>;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  voucherNumber: number;
}

const MoneyReceivedHeader: React.FC<MoneyReceivedHeaderProps> = ({
  customerName,
  setCustomerName,
  customers,
  filteredCustomers,
  setFilteredCustomers,
  isCustomerSearchFocused,
  setIsCustomerSearchFocused,
  handleCustomerSelect,
  setPreviousOutstanding,
  setSelectedCustomer,
  previousOutstanding,
  handleKeyPress,
  voucherNumberRef,
  date,
  setDate,
  voucherNumber,
}) => {
  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      setDate(newDate);
    }
  };

  const handleCustomerKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleKeyPress(e);
  };

  return (
    <div className="flex flex-wrap -mx-2 mb-4">
      <div className="w-2/12 px-1 mb-6 md:mb-0">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="date">
          Date
        </label>
        <DatePicker
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholderText="Select date"
          id="date"
        />
      </div>

      <div className="w-6/12 px-1 mb-6 md:mb-0">
        <CustomerSearch
          customerName={customerName}
          setCustomerName={setCustomerName}
          customers={customers}
          filteredCustomers={filteredCustomers}
          setFilteredCustomers={setFilteredCustomers}
          isCustomerSearchFocused={isCustomerSearchFocused}
          setIsCustomerSearchFocused={setIsCustomerSearchFocused}
          handleCustomerSelect={handleCustomerSelect}
          setPreviousOutstanding={setPreviousOutstanding}
          setSelectedCustomer={setSelectedCustomer}
          previousOutstanding={previousOutstanding}
          handleKeyPress={(e, ref) => handleCustomerKeyPress(e)}
          voucherNumberRef={voucherNumberRef}
        />
      </div>

      <div className="w-4/12 px-1 mb-6 md:mb-0 text-right">
        <div className="text-gray-700 font-bold" ref={voucherNumberRef}>
          Voucher Number: {voucherNumber}
        </div>
      </div>
    </div>
  );
};

export default MoneyReceivedHeader;
