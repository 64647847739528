import React, { useState, useEffect } from 'react';
import { getAllLoans } from '../api/LoanAPI';
import { Loan } from '../types/loanTypes';

interface PreviousLoansProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: (loanId: string) => void;
}

const PreviousLoans: React.FC<PreviousLoansProps> = ({ isOpen, onClose, onEdit }) => {
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLoans = async () => {
      if (isOpen) {
        setLoading(true);
        try {
          const response = await getAllLoans();
          if (response.success && response.data) {
            setLoans(response.data);
          }
        } catch (err) {
          setError('Error fetching loans');
          console.error('Error fetching loans:', err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLoans();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[80vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Previous Loans</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-4">{error}</div>
        ) : (
          <div className="overflow-auto flex-grow">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Date</th>
                  <th className="px-4 py-2 text-left">Voucher No.</th>
                  <th className="px-4 py-2 text-left">Customer Name</th>
                  <th className="px-4 py-2 text-left">Total Amount</th>
                  <th className="px-4 py-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {loans.map((loan) => (
                  <tr key={loan._id} className="border-b hover:bg-gray-50">
                    <td className="px-4 py-2">
                      {new Date(loan.date).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">{loan.voucherNumber}</td>
                    <td className="px-4 py-2">
                      {typeof loan.customer === 'object' ? loan.customer.name : loan.customer}
                    </td>
                    <td className="px-4 py-2">{loan.totalAmount}</td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => {
                          onEdit(loan._id!);
                          onClose();
                        }}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviousLoans;
