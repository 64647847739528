import React, { useState } from 'react';
import { Vendor } from './VendorForm';

interface VendorListProps {
  vendors: Vendor[];
  onEdit: (vendor: Vendor) => void;
  onDelete: (vendorId: string) => void;
}

const VendorList: React.FC<VendorListProps> = ({ vendors, onEdit, onDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredVendors = vendors.filter(vendor =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vendor.phone.includes(searchTerm) ||
    (vendor.note && vendor.note.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="bg-white shadow-md rounded px-4 py-4 overflow-x-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by vendor name, phone number or note"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Contact
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Address
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              City
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Note
            </th>
            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredVendors.map((vendor) => (
            <tr key={vendor._id}>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 font-medium text-gray-900">{vendor.name}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{vendor.phone}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{vendor.address}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{vendor.city}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <div className="text-sm leading-5 text-gray-500">{vendor.note || ''}</div>
              </td>
              <td className="px-4 py-2 whitespace-no-wrap border-b border-gray-500">
                <button
                  onClick={() => onEdit(vendor)}
                  className="text-indigo-600 hover:text-indigo-900 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => onDelete(vendor._id)}
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VendorList;
