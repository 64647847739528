import React, { useCallback } from 'react';

interface LoanDetail {
  amt: string;
  monthlyInt: string;
  note: string;
}

interface LoanDetailsTableProps {
  details: LoanDetail[];
  setDetails: (details: LoanDetail[]) => void;
}

const LoanDetailsTable: React.FC<LoanDetailsTableProps> = ({ details, setDetails }) => {
  const handleInputChange = useCallback((index: number, field: keyof LoanDetail, value: string) => {
    const updatedDetails = details.map((detail, i) => {
      if (i === index) {
        if (field === 'amt' || field === 'monthlyInt') {
          // Ensure value is not negative
          const numValue = parseFloat(value) || 0;
          const validValue = Math.max(0, numValue).toString();
          return { ...detail, [field]: validValue };
        }
        return { ...detail, [field]: value };
      }
      return detail;
    });
    setDetails(updatedDetails);
  }, [details, setDetails]);

  const addNewRow = () => {
    setDetails([...details, {
      amt: '0',
      monthlyInt: '0',
      note: ''
    }]);
  };

  const calculateTotal = () => {
    return details.reduce((total, detail) => total + (parseFloat(detail.amt) || 0), 0).toFixed(2);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-4 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base w-6">Sr.</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Amt</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Monthly Int %</th>
            <th className="border border-gray-300 px-1 py-1 whitespace-nowrap text-base">Note</th>
          </tr>
        </thead>
        <tbody>
          {details.map((detail, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-1 py-1">{index + 1}</td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={detail.amt === '0' ? '' : detail.amt}
                  onChange={(e) => handleInputChange(index, 'amt', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={detail.monthlyInt === '0' ? '' : detail.monthlyInt}
                  onChange={(e) => handleInputChange(index, 'monthlyInt', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 px-1 py-1">
                <input
                  type="text"
                  value={detail.note}
                  onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                  className="w-full"
                  placeholder="Enter note"
                />
              </td>
            </tr>
          ))}
          <tr className="bg-gray-100">
            <td className="border border-gray-300 px-1 py-1 font-bold">Total</td>
            <td className="border border-gray-300 px-1 py-1 font-bold">{calculateTotal()}</td>
            <td className="border border-gray-300 px-1 py-1"></td>
            <td className="border border-gray-300 px-1 py-1"></td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        onClick={addNewRow}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded text-sm"
      >
        +
      </button>
    </div>
  );
};

export default LoanDetailsTable;
