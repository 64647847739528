import React from 'react';
import { useParams } from 'react-router-dom';
import { LoanIssue as LoanIssueForm } from '../features/loan/components';

const LoanIssuePage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <LoanIssueForm loanId={id} />
    </div>
  );
};

export default LoanIssuePage;
