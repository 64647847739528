import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

// Create axios instance with default config
const api = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Add request interceptor to include auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Gold Items Interface
export interface GoldItem {
  itemId: string;  // Keep as string in frontend, will be converted to ObjectId in backend
  itemName: string;
  pcs: string;
  grossWt: string;
  lessWt: string;
  netWt: string;
  tunch: string;
  wstg: string;
  fine: string;
}

// Silver Items Interface
export interface SilverItem {
  fine: string;
}

export interface VendorBill {
  _id?: string;
  voucherNumber: number;
  vendor: string;  // Keep as string in frontend, will be converted to ObjectId in backend
  selectedVendor: string;
  date: Date;
  goldItems: GoldItem[];
  silverItems: SilverItem[];
  outstandingGoldFine: string;
  outstandingSilverFine: string;
}

interface VendorBillsResponse {
  vendorBills: VendorBill[];
  nextVoucherNumber: number;
}

// Helper function to validate gold item
const isValidGoldItem = (item: GoldItem): boolean => {
  return !!(
    item.itemId &&
    item.itemName &&
    item.pcs &&
    item.grossWt &&
    item.lessWt &&
    item.netWt &&
    item.tunch &&
    item.wstg &&
    item.fine
  );
};

export const createVendorBill = async (vendorBillData: Partial<VendorBill>) => {
  try {
    // Filter out invalid gold items
    const validGoldItems = vendorBillData.goldItems?.filter(isValidGoldItem) || [];
    
    // Only send valid gold items
    const dataToSend = {
      ...vendorBillData,
      goldItems: validGoldItems
    };

    const response = await api.post('/vendor-bills', dataToSend);
    return response.data;
  } catch (error) {
    console.error('Error creating vendor bill:', error);
    throw error;
  }
};

export const getVendorBills = async (): Promise<VendorBillsResponse> => {
  try {
    const response = await api.get('/vendor-bills');
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor bills:', error);
    throw error;
  }
};

export const getVendorBill = async (id: string) => {
  try {
    const response = await api.get(`/vendor-bills/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor bill:', error);
    throw error;
  }
};

export const getVendorBillsByVendor = async (vendorId: string) => {
  try {
    const response = await api.get(`/vendor-bills/vendor/${vendorId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor bills:', error);
    throw error;
  }
};

export const updateVendorBill = async (id: string, vendorBillData: Partial<VendorBill>) => {
  try {
    // Filter out invalid gold items
    const validGoldItems = vendorBillData.goldItems?.filter(isValidGoldItem) || [];
    
    // Only send valid gold items
    const dataToSend = {
      ...vendorBillData,
      goldItems: validGoldItems
    };

    const response = await api.put(`/vendor-bills/${id}`, dataToSend);
    return response.data;
  } catch (error) {
    console.error('Error updating vendor bill:', error);
    throw error;
  }
};

export const deleteVendorBill = async (id: string) => {
  try {
    const response = await api.delete(`/vendor-bills/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting vendor bill:', error);
    throw error;
  }
};

export const fetchVendorBillDetails = async (billId: string) => {
  try {
    const response = await api.get(`/vendor-bills/${billId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vendor bill:', error);
    throw error;
  }
};

export const fetchNearestPreviousVendorBill = async (voucherNumber: number) => {
  try {
    const response = await api.get(`/vendor-bills/previous/${voucherNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching previous vendor bill:', error);
    throw error;
  }
};

export const fetchNearestNextVendorBill = async (voucherNumber: number) => {
  try {
    const response = await api.get(`/vendor-bills/next/${voucherNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching next vendor bill:', error);
    throw error;
  }
};
